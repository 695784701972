<template>
  <div class="h-100 d-flex flex-column">
    <div class="pa-3 text-center primary">
      <h5 class="white--text pt-1 font-weight-semibold">{{ $t('chatWindow.selectThread') }}</h5>
    </div>
    <div class="multi-thread h-100">
      <div v-for="thread in threads" :key="thread.id">
        <v-row dense class="mt-0 px-4 cursor-pointer d-flex align-center"
          :class=" anyUnreadedMessages(thread) ? 'primary lighten-2 white--text' : 'grey lighten-2'"
          @click="changeThread(thread)"
        >
          <v-col cols="10">
            <span class="font-weight-black">{{ getConversationType(thread) }}</span>
            <br />
            <span>{{ getThreadName(thread) }}</span>
            <!-- <div class="ml-auto mr-2" v-if="anyUnreadedMessages(thread)">
            <v-badge dot color="blue"></v-badge>
          </div> -->
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-icon size="25" color="primary" class="my-1">
              $arrowsRight
            </v-icon>
          </v-col>
        </v-row>
        <v-row dense v-if="lastMessages(thread)">
          <v-col>
            <message
              :value="message"
              v-for="message in lastMessages(thread)"
              :key="message.id"
              :threadId="thread.id"
            ></message>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Message from '../Messages/Message.vue'
import Messenger from '@/utils/messenger.js'
export default {
  components: {
    Message
  },
  props: {
    conversation: Object
  },
  computed: {
    threads () {
      return this.conversation.threads
    },
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    conversationThreadTypes () {
      return this.$store.getters.enums.conversationThreadType
    }
  },
  methods: {
    getConversationType (thread) {
      return this.conversationThreadTypes?.find((el) => el.id === thread.type)
        ?.description
    },
    anyUnreadedMessages (thread) {
      if (!thread.messages) {
        return false
      }

      const lastMessage = thread.messages[thread.messages.length - 1]
      if (lastMessage) {
        return lastMessage.users?.find(
          (user) => user.id === this.currentUserId && !user.read
        )
      }
      return false
    },
    getThreadName (thread) {
      if (thread.type === 0) {
        return ''
      }
      return thread.name
    },
    changeThread (thread) {
      if (this.$store.getters['chat/isUnpinedWindow']) {
        Messenger.canIChangeThread(this.conversation.id, null, thread.id)
      } else {
        this.$store.dispatch('chat/changeCurrentThreadInWindow', {
          conversationId: this.conversation.id,
          currentThreadId: null,
          threadId: thread.id
        })
      }
    },
    lastMessages (thread) {
      const lastMyMessage = thread.messages
        .filter((o) => o.sender === this.currentUserId)
        .slice(-1)
        .pop()
      const lastOtherMessage = thread.messages
        .filter((o) => o.sender !== this.currentUserId)
        .slice(-1)
        .pop()
      if (lastMyMessage) {
        lastMyMessage.showDate = true
        lastMyMessage.showAvatar = true
      }
      if (lastOtherMessage) {
        lastOtherMessage.showDate = true
        lastOtherMessage.showAvatar = true
      }
      if (lastMyMessage && !lastOtherMessage) {
        return [lastMyMessage]
      } else if (lastOtherMessage && !lastMyMessage) {
        return [lastOtherMessage]
      } else if (!lastMyMessage && !lastOtherMessage) {
        return []
      }
      if (lastMyMessage.creationDate <= lastOtherMessage.creationDate) {
        return [lastMyMessage, lastOtherMessage]
      }
      return [lastOtherMessage, lastMyMessage]
    }
  }
}
</script>

<style scoped>
.multi-thread {
  overflow-y: auto;
  overflow-x: hidden;
}
.title {
  border-bottom: 1px solid grey;
}
</style>
