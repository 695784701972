<template>
  <v-list-item :class="stackItemClass" @click="focusConversation" class="pa-2 stack-item" dense>
    <div class="d-flex align-center">
      <StatusAvatar v-if="otherUser" :userId="otherUser.id" avatarColor="primary" textColor="white" :size="24" showStatus  />
      <div class="ml-2 body-2">{{ fullName | trimText(15) }}</div>
    </div>
  </v-list-item>
</template>

<script>
import StatusAvatar from '@/components/StatusAvatar'
import { NEW_DEAL_MESSAGE, NEW_MESSAGE } from '@/types/new-message-type'

export default {
  name: 'StackItem',
  components: {
    StatusAvatar
  },
  props: {
    conversation: Object
  },
  computed: {
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    usersWithoutCurrent () {
      return this.conversation.conversation.users.filter(
        (el) => el.user.id !== this.currentUserId
      )
    },
    fullName () {
      if (!this.otherUser) {
        return this.$t('chatSidebar.help')
      }
      return this.otherUser.fullName
    },
    otherUser () {
      return this.usersWithoutCurrent[0]?.user
    },
    stackItemClass () {
      return {
        'new-message': this.conversation.hasNewMessage && this.conversation.newMessageType === NEW_MESSAGE,
        'new-deal-message': this.conversation.hasNewMessage && this.conversation.newMessageType === NEW_DEAL_MESSAGE
      }
    }
  },
  methods: {
    focusConversation () {
      this.$store.dispatch('chat/openConversation', { conversationId: this.conversation.id, threadId: this.conversation.currentThreadId })
    }
  }
}
</script>
<style>
.stack-item:after {
  content: '';
  position: absolute;
  border-bottom: solid 2px rgba(52, 58, 61, 0.1);
  width: calc(100% - 16px);
  left: 8px;
  bottom: 0;
}
.stack-item:last-child:after {
  border: none;
}
</style>
