import * as types from './market-mutation-types'
import Service from '@/services'
import { generateString } from '@/utils/helpers'
import { catchMarketError, newFilter } from '@/utils/market'
import { FREIGHT, MY_FREIGHT, MY_VEHICLE, VEHICLE } from '@/types/order-types'
import { BLOCKEDCOOPERATION } from '@/types/condition-types'

const getDefaultState = () => ({
  loading: false,
  orderType: null,
  filters: [newFilter()],
  selectedTab: 0,
  expandedTab: true,
  items: JSON.stringify({
    count: 0,
    page: 1,
    results: []
  }),
  showScrollToTopButton: false
})

const state = getDefaultState()

const getters = {
  loading (state) {
    return state.loading
  },
  orderType (state) {
    return state.orderType
  },
  filters (state) {
    return state.filters
  },
  selectedTab (state) {
    return state.selectedTab
  },
  expandedTab (state) {
    return state.expandedTab
  },
  currentTab (state) {
    return state.filters.length > 0 ? state.filters[state.selectedTab] : null
  },
  isMaxNewTab (state) {
    const newTabLength = state.filters.filter((t) => t.newTab)
    return newTabLength.length > 0
  },
  items (state) {
    return state.items && state.items.length > 0
      ? JSON.parse(state.items).results
      : []
  },
  totalItems (state) {
    return JSON.parse(state.items).count
  },
  showScrollToTopButton (state) {
    return state.showScrollToTopButton
  }
}

const actions = {
  async fetchFilters ({ state, commit }) {
    try {
      const { data } = await Service.get('/Filter/GetFiltersForTab', {
        type: state.orderType
      })
      if (data && data.length > 0) {
        commit(types.SET_FILTER, data)
      } else {
        commit(types.SET_FILTER, [newFilter()])
      }
    } catch (e) {
      console.log(e)
    }
  },
  async addFilter ({ state, getters, commit, dispatch }, filters) {
    try {
      const { data } = await Service.post('/Filter/Add', filters)
      if (getters.currentTab.newTab) {
        const newTabs = state.filters.map((f) => {
          if (f.newTab) {
            return {
              budge: 0,
              conditions: filters.model.conditions,
              hardcodedKey: null,
              id: data,
              isHardcoded: false,
              name: filters.name
            }
          }
          return f
        })
        commit(types.SET_FILTER, newTabs)
      }
    } catch (e) {
      await catchMarketError(e)
    }
  },
  async updateFilter ({ getters, commit, dispatch }, filters) {
    try {
      await Service.put('/Filter/Update', {
        model: {
          ...filters.model,
          filterId: getters.currentTab.id
        }
      })
      await dispatch('fetchItems')
    } catch (e) {
      await catchMarketError(e, true, () => {
        dispatch('removeFilterOnlyClient', getters.currentTab.id)
      })
    }
  },
  async submitFilter ({ state, dispatch, getters }, filters) {
    if (getters.currentTab.newTab) {
      await dispatch('addFilter', filters)
    } else {
      await dispatch('updateFilter', filters)
    }
    await dispatch('fetchFilters')
  },
  async removeFilterOnlyClient ({ commit, getters }, filterId) {
    if (getters.currentTab.id === filterId) {
      commit(types.SET_SELECTED_TAB, 0)
    }
    commit(types.REMOVE_FILTER, filterId)
  },
  async removeFilter ({ commit, dispatch }, filterId) {
    try {
      if (getters.currentTab.id === filterId) {
        commit(types.SET_SELECTED_TAB, 0)
      }
      await Service.delete('/Filter/Delete', { filterId })
      await dispatch('fetchFilters')
    } catch (e) {
      await catchMarketError(e, true, () => {
        dispatch('removeFilterOnlyClient', filterId)
      })
    }
  },
  async fetchItems ({ getters, commit, dispatch }) {
    if (getters.currentTab.newTab) {
      return
    }
    commit(types.SET_LOADING, true)
    try {
      const orderType = getters.orderType

      let uri = null

      switch (orderType) {
        case MY_FREIGHT:
        case FREIGHT:
          uri = '/FreightOrder/GetFiltered'
          break
        case MY_VEHICLE:
        case VEHICLE:
          uri = '/VehicleOrder/GetFiltered'
          break
      }

      const { data } = await Service.get(uri, {
        filterId: getters.currentTab.id
      })
      commit(types.UPDATE_ITEMS, data)
    } catch (e) {
      await catchMarketError(e, true, () => {
        dispatch('removeFilterOnlyClient', getters.currentTab.id)
      })
    } finally {
      commit(types.SET_LOADING, false)
    }
  },
  addItem ({ getters, commit }, payload) {
    if (getters.currentTab.id === payload.filterId) {
      commit(types.ADD_ITEM, payload)
    } else {
      commit(types.ADD_BUDGE, payload)
    }
  },
  removeItem ({ getters, commit }, payload) {
    if (getters.currentTab.id !== payload.filterId) {
      return
    }
    commit(types.REMOVE_ITEM, payload)
  },
  modifiedItem ({ getters, commit }, payload) {
    if (getters.currentTab.id !== payload.filterId) {
      return
    }

    const item = getters.items.find((i) => i.id === payload.data.id)
    if (item) {
      commit(types.REMOVE_ITEM, {
        filterId: payload.payload,
        data: payload.data.id
      })
      commit(types.ADD_ITEM, payload)
    } else {
      commit(types.ADD_ITEM, payload)
    }
  },
  clearBudge ({ getters, commit }) {
    if (!getters.currentTab || !getters.currentTab.id) {
      return
    }
    commit(types.CLEAR_BUDGE, getters.currentTab.id)
  },
  async changeTab ({ getters, commit, dispatch }, selectedTab) {
    commit(types.SET_LOADING, true)
    commit(types.SET_SELECTED_TAB, selectedTab)
    commit(types.SET_BLANK_ITEMS)
    const currentTab = getters.currentTab
    if (currentTab && !currentTab.newTab) {
      // await dispatch('fetchItems')
      await dispatch('clearBudge')
    }
    if (currentTab) {
      commit(types.SET_EXPANDED_TAB, !!currentTab.newTab)
    }
    commit(types.SET_LOADING, false)
  },
  resetState ({ commit }) {
    commit(types.RESET_STATE)
  }
}

const mutations = {
  [types.SET_LOADING] (state, loading) {
    state.loading = loading
  },
  [types.SET_ORDER_TYPE] (state, type) {
    state.orderType = type
  },
  [types.SET_FILTER] (state, filters) {
    state.filters = filters.map((f) => ({ ...f, budge: 0 }))
  },
  [types.ADD_FILTER] (state) {
    state.filters.push({
      newTab: true,
      id: generateString(),
      budge: 0,
      conditions: [
        {
          type: BLOCKEDCOOPERATION,
          value: true
        }
      ],
      hardcodedKey: null,
      isHardcoded: false
    })
  },
  [types.REMOVE_FILTER] (state, tabId) {
    state.filters = state.filters.filter((t) => t.id !== tabId)
  },
  [types.SET_SELECTED_TAB] (state, tab) {
    state.selectedTab = tab
  },
  [types.SET_EXPANDED_TAB] (state, tab) {
    state.expandedTab = tab
  },
  [types.CLEAR_ITEMS] (state) {
    state.items = JSON.stringify({
      count: 0,
      page: 1,
      results: []
    })
  },
  [types.UPDATE_ITEMS] (state, items) {
    const data = JSON.parse(state.items)
    state.items = JSON.stringify({
      ...items,
      results: [
        ...data.results,
        ...items.results
      ]
    })
  },
  [types.ADD_ITEM] (state, payload) {
    const items = JSON.parse(state.items)
    items.results = [payload.data, ...items.results]
    items.count = items.count + 1
    state.items = JSON.stringify(items)
  },
  [types.REMOVE_ITEM] (state, payload) {
    const items = JSON.parse(state.items)
    items.results = items.results.filter(i => i.id !== payload.data)
    items.count = items.count - 1
    state.items = JSON.stringify(items)
  },
  [types.ADD_BUDGE] (state, payload) {
    const tabs = JSON.parse(JSON.stringify(state.filters))
    state.filters = tabs.map((i) => {
      if (i.id !== payload.filterId) {
        return i
      } else {
        return {
          ...i,
          budge: i.budge + 1
        }
      }
    })
  },
  [types.CLEAR_BUDGE] (state, filterId) {
    const tabs = JSON.parse(JSON.stringify(state.filters))
    state.filters = tabs.map((i) => {
      if (i.id !== filterId) {
        return i
      } else {
        return {
          ...i,
          budge: 0
        }
      }
    })
  },
  [types.SET_BLANK_ITEMS] (state) {
    state.items = JSON.stringify({
      count: 0,
      page: 1,
      results: []
    })
  },
  [types.RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [types.SET_SHOW_SCROLL_TO_TOP_BUTTON] (state, showScrollToTopButton) {
    state.showScrollToTopButton = showScrollToTopButton
  },
  [types.MARK_SHOW_OFFER] (state, payload) {
    const items = JSON.parse(state.items)
    const offer = items.results.find(o => o.id === payload.id)
    if (!offer) {
      return
    }
    offer.checked = true
    state.items = JSON.stringify(items)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
