<template>
<div>
  <v-divider />
  <v-row class="my-2" no-gutters>
      <v-col cols="12" v-for="company in limitedCompanies" :key="company.id">
        <span>{{ company.name }} <strong>{{ company.displayId }}</strong></span>
      </v-col>
  </v-row>
</div>
  <!-- <v-row no-gutters class="pt-4">
    <v-col cols="12">
      <p v-for="company in limitedCompanies" :key="company.id">
        {{ company.name }} <strong>{{ company.displayId }}</strong>
      </p>
    </v-col>
  </v-row> -->
</template>

<script>
export default {
  name: 'PrivatePanel',
  props: {
    limitedCompanies: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
