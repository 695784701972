<template>
  <div>
    <v-dialog v-model="dialog" width="550" persistent>
      <v-card>
        <v-card-title>{{ $t('sendSmsDialog.title') }}</v-card-title>
        <v-card-text>
          <label>
            {{ $t('sendSmsDialog.phoneNumber') }}
          </label>
          <v-text-field
            v-model="phoneNumber"
            :disabled="loading"
            :loading="loading"
            outlined
          >
          </v-text-field>
          <label>{{ $t('sendSmsDialog.text') }}</label>
          <v-text-field
            :disabled="loading"
            :loading="loading"
            v-model="text"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn :loading="loading" :disabled="loading" color="primary" @click="send" depressed>{{ $t('common.send') }}</v-btn>
          <v-btn :loading="loading" :disabled="loading" outlined @click="close" depressed>{{ $t('common.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Services from '@/services'
export default {
  data () {
    return {
      dialog: false,
      phoneNumber: '',
      text: '',
      loading: false
    }
  },
  methods: {
    open (phoneNumber) {
      this.phoneNumber = phoneNumber
      this.text = ''
      this.dialog = true
    },
    close () {
      this.phoneNumber = ''
      this.text = ''
      this.dialog = false
    },
    async send () {
      this.loading = true
      try {
        await Services.post('Chat/SendSms', {
          phone: this.phoneNumber,
          message: this.text
        })
        this.close()
        this.$store.dispatch(
          'setSuccessNotification',
          this.$t('sendSmsDialog.success')
        )
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
