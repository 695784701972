export const SEARCH = 1
export const MIN = 2
export const MAX = 3
export const LOCATIONSTRAIGHT = 4
export const LOCATIONCROSS = 5
export const ONLYMINE = 6
export const MYCOMPANY = 7
export const MULTI = 8
export const BLOCKEDCOOPERATION = 9
export const MYCOMPANYNEGOTIATIONS = 10
export const NEGOTIATEDBYCOMPANY = 11
export const CABOTAGE = 12
export const DEALBYCOMPANY = 13
export const DEALBYUSER = 14
export const GROUP = 15
