<template>
 <div>
  <v-divider/>
  <v-row class="my-2" no-gutters>
    <v-col cols="12" sm="6" md="4">
      <h4 class="primary--text">{{ $t('offerDetails.vehicleSize') }}:</h4>
      <p>
        {{ getVehicleSize(details.size) }}
      </p>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <h4 class="primary--text">{{ $t('offerDetails.loadFormType') }}:</h4>
      <p>
        <span v-for="(type, index) in details.loadForms" :key="index">{{ getLoadFormType(type) }} </span>
      </p>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <h4 class="primary--text">{{ $t('offerDetails.loadMeters') }}:</h4>
      <p>
        {{ details.loadMeters }}
      </p>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <h4 class="primary--text">{{ $t('offerDetails.vehicleType') }}:</h4>
      <p>
        {{ details.type }}
      </p>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <h4 class="primary--text">{{ $t('offerDetails.size') }}:</h4>
      <p>
        {{ details.width }} x {{details.height }} x {{ details.length }}
      </p>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <h4 class="primary--text">{{ $t('offerDetails.vehicleOptions') }}:</h4>
      <p>
        <span v-for="(option, index) in details.options" :key="index">{{ option }} </span>
      </p>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <h4 class="primary--text">{{ $t('offerDetails.capacity') }}:</h4>
      <p>
        {{ details.load }}t
      </p>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <h4 class="primary--text">{{ $t('offerDetails.volume') }}:</h4>
      <p>
        {{ details.volume}} m3
      </p>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <h4 class="primary--text">{{ $t('offerDetails.pricePerKm') }}:</h4>
      <p>
        {{ details.price }} {{ offerCurrency }}
      </p>
    </v-col>
  </v-row>
  <v-divider/>
  <v-row class="my-2" no-gutters>
    <v-col cols="12" sm="7" class="pr-sm-4">
      <h4 class="primary--text">{{ $t('offerDetails.description') }}:</h4>
      <p>
        {{ details.additionalDescription }}
      </p>
    </v-col>
    <v-col cols="12" sm="5">
      <h4 class="primary--text">{{ $t('offerDetails.contactPerson') }}:</h4>
      <p>
        <span>{{ details.publisher | trimText(25) }}</span>
        <v-icon v-if="displayMessageAction" icon @click.stop="startConversationWithUser(details.publisherId)" color="separator" size="18" class="ml-2 mb-4">$conversation</v-icon>
      </p>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import { startConversation } from '@/signalr/chat'
import { getEnumDescription } from '@/utils/helpers'

export default {
  name: 'FreightDetailsPanel',
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  computed: {
    displayMessageAction () {
      return this.$store.getters.userCommonData.id !== this.details.publisherId
    },
    allCurrencies () {
      return this.$store.getters.allCurrencies
    },
    offerCurrency () {
      return getEnumDescription(this.allCurrencies, this.details.currency)
    }
  },
  methods: {
    getVehicleSize (size) {
      return this.$store.getters.enums.vehicleSizeTypes.find(type => type.id === size).description
    },
    getLoadFormType (type) {
      return this.$store.getters.enums.vehicleLoadFormTypes.find(formType => formType.id === type).description
    },
    startConversationWithUser (userId) {
      startConversation(userId)
    }
  }
}
</script>
