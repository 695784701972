<template>
  <div class="user-details pa-2 pl-3 pb-0" v-if="!isSupportThread">
    <div class="d-flex justify-space-between pb-2 line">
      <div class="d-flex align-center">
        <StatusAvatar :userId="otherUser.id" :size="30" showStatus class="mr-2" />
        <div class="d-flex flex-column">
          <h5 @click="otherUser.company ? openUserProfileView(otherUser.id) : null" :class="{ 'pointer': otherUser.company }">
            {{ otherUser.fullName | trimText(25) }}
          </h5>
          <span v-if="currentWindow.minimalize">
            {{  getCompanyName(otherUser) | trimText(30) }}
          </span>
          <span v-else @click="otherUser.company ? openUserCompanyView(otherUser.company.id) : null" :class="{ 'pointer': otherUser.company }">
            {{  getCompanyName(otherUser) }}
          </span>
        </div>
      </div>
      <ActionButtons
        :id="conversationId"
        :current-thread-id="currentThreadId"
        @closeWindow="closeWindow"
        @minimalizeWindow="minimalizeWindow"
        @unpinWindow="unpinWindow"
      />
    </div>
    <div class="d-flex align-center py-2" :class="{ 'line': !currentWindow.minimalize }">
      <v-menu v-if="userIsWorker" offset-y :close-on-content-click="false" v-model="contactMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small icon v-on="on" v-bind="attrs" tile outlined class="rounded" color="primary">
            <v-icon size="23">$user</v-icon>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-progress-linear indeterminate :active="loading" />
          <v-list-item dense v-if="groups && groups.length > 0">
            {{ $t('userDetails.addToGroup') }}
          </v-list-item>
          <v-list-item dense v-for="group in groups" :key="group.id">
            <v-checkbox :disabled="loading" :readonly="loading" dense
              :input-value="isUserInGroup(group)"
              :label="group.name"
              @click="groupClick(group)"
              hide-details
            ></v-checkbox
          ></v-list-item>
          <v-divider class="mx-3 mt-1" />
          <v-list-item dense :disabled="loading" @click="openAddGroupDialog">
            {{ $t('userDetails.addGroup') }}
          </v-list-item>
          <v-divider class="mx-3" />
          <v-list-item v-if="isUserInContact" :disabled="loading" @click="removeFromContact" dense>
            {{ $t('userDetails.removeWithContacts') }}
          </v-list-item>
          <v-list-item v-else :disabled="loading" @click="addToContact" dense>
            {{ $t('userDetails.addWithContacts') }}
          </v-list-item>
          <v-divider class="mx-3" />
          <v-list-item v-if="!blockedBy" :disabled="loading" @click="blockUser" dense>
            {{ $t('userDetails.blockUser') }}
          </v-list-item>
          <v-list-item v-else-if="currentUserId === blockedBy" :disabled="loading" @click="unblockUser" dense>
            {{ $t('userDetails.unblockUser') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="d-flex flex-column ml-3">
        <span>Tel. {{ otherUser.phone }}</span>
        <template v-if="otherUserDescription && otherUserDescription.showChatDescription">
          <span v-if="currentWindow.minimalize">
            {{ chatDescription | trimText(40) }}
          </span>
          <span v-else class="description">
            {{ chatDescription }}
          </span>
        </template>
      </div>
    </div>
    <AddGroupDialog ref="addGroupDialog" @save="addGroupSaved" />
  </div>
  <div v-else-if="isSupportThread" class="shadow-bottom">
    <div class="pa-2 pl-3 pb-0" :class="{ 'primary': !hasNewMessage}">
      <div class="d-flex justify-space-between pb-3 pt-1">
        <div class="d-flex align-center">
          <v-avatar color="white" size="30" class="rounded" tile>
            <v-icon size="26" color="primary">$bot</v-icon>
          </v-avatar>
          <div v-if="userIsWorker" class="d-flex flex-column ml-2">
            <h3 class="font-weight-semibold" :class="hasNewMessage ? 'primary--text' : 'white--text'" style="line-height:16px">{{ $t('chatWindow.supportSpedimo') }}</h3>
            <h4 v-if="otherUser" class="font-weight-semibold" :class="hasNewMessage ? 'primary--text' : 'accent--text'" style="line-height:14px">{{ otherUser.fullName }}</h4>
          </div>
          <div v-else class="d-flex flex-column ml-2">
            <h3 class="font-weight-semibold" :class="hasNewMessage ? 'primary--text' : 'white--text'" style="line-height:16px">{{ $t('chatWindow.supportSpedimo') }}</h3>
            <h4 class="font-weight-semibold pointer" @click="otherUser.company && !currentWindow.minimalize ? openUserProfileView(otherUser.id) : null" :class="hasNewMessage ? 'primary--text' : 'accent--text'">
              {{ otherUser.fullName | trimText(25) }}
            </h4>
            <span class="white--text" v-if="currentWindow.minimalize">
              {{  getCompanyName(otherUser) | trimText(30) }}
            </span>
            <span class="white--text" v-else @click="otherUser.company ? openUserCompanyView(otherUser.company.id) : null" :class="{ 'pointer': otherUser.company }">
              {{  getCompanyName(otherUser) }}
            </span>
          </div>
        </div>
        <ActionButtons
          :color="hasNewMessage ? 'black' : 'white'"
          :id="conversationId"
          :current-thread-id="currentThreadId"
          @closeWindow="closeWindow"
          @minimalizeWindow="minimalizeWindow"
          @unpinWindow="unpinWindow"
        />
      </div>
    </div>
    <div v-if="!otherUser && !currentWindow.minimalize" class="white py-4">
      <h5 class="text-center primary--text">{{ $t('chatWindow.waitingForWorker') }}</h5>
    </div>
  </div>
</template>

<script>
import ActionButtons from '@/chat/Window/ActionButtons'
import StatusAvatar from '@/components/StatusAvatar'
import Services from '@/services'
import AddGroupDialog from './Dialogs/AddOrEditChatGroupDialog'

export default {
  components: {
    ActionButtons,
    StatusAvatar,
    AddGroupDialog
  },
  props: {
    users: Array,
    conversationId: String,
    currentThreadId: String,
    conversationType: Number,
    unpined: Boolean,
    blockedBy: [String, null],
    isSupportThread: Boolean,
    hasNewMessage: Boolean
  },
  data () {
    return {
      isOnline: false,
      contactMenu: false,
      loading: false
    }
  },
  computed: {
    currentWindow () {
      return this.$store.getters['chat/windows'].find(
        (el) => el.id === this.conversationId && el.currentThreadId === this.currentThreadId
      )
    },
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    usersWithoutCurrent () {
      return this.users.filter((el) => el.user.id !== this.currentUserId)
    },
    otherUser () {
      return this.usersWithoutCurrent[0]?.user
    },
    otherUserDescription () {
      return this.$store.getters['users/users'].find((el) => el.id === this.otherUser?.id)
    },
    contacts () {
      return this.$store.getters.contacts
    },
    userIsWorker () {
      return this.$store.getters.isMarketUser
    },
    isUserInContact () {
      if (!this.userIsWorker) {
        return false
      }
      if (!this.otherUser) {
        return false
      }
      const otherUserId = this.otherUser?.id
      return !!this.contacts?.contacts?.find((el) => el.user.id === otherUserId)
    },
    groups () {
      return this.contacts.groups
    },
    chatDescription () {
      return this.otherUserDescription && this.otherUserDescription.chatDescription ? this.otherUserDescription.chatDescription : ''
    }
  },
  methods: {
    closeWindow (id) {
      this.$emit('closeWindow', id)
    },
    minimalizeWindow () {
      this.$emit('minimalizeWindow')
    },
    unpinWindow (arg) {
      this.$emit('unpinWindow', arg)
    },
    getCompanyName (user) {
      if (this.isSupportThread && user.company) {
        return `${user.company.name} (${user.company.displayId})`
      }
      if (!user.company) {
        return ''
      }
      return `${user.company.name} (${user.company.displayId})`
    },
    async clickContact () {
      if (!this.userIsWorker) {
        return
      }
      if (!this.isUserInContact) {
        await this.addToContact()
      }
    },
    async addToContact () {
      this.loading = true
      try {
        await Services.post('Contact/AddContact', { userId: this.otherUser.id })
        await this.refreshContacts()
      } finally {
        this.loading = false
      }
    },
    async removeFromContact () {
      this.loading = true
      try {
        await Services.delete('Contact/DeleteContact', {
          userId: this.otherUser.id
        })
        await this.refreshContacts()
      } finally {
        this.loading = false
      }
    },
    async refreshContacts () {
      await this.$store.dispatch('refreshContacts')
    },
    openAddGroupDialog () {
      this.$refs.addGroupDialog.open()
    },
    async addGroupSaved () {
      await this.refreshContacts()
    },
    isUserInGroup (group) {
      const result = group.users.find((el) => el.user.id === this.otherUser.id)
      return !!result
    },
    async groupClick (group) {
      this.loading = true
      try {
        const model = {
          userId: this.otherUser.id,
          groupId: group.id
        }
        if (!this.isUserInGroup(group)) {
          await Services.post('Contact/AddToGroup', model)
        } else {
          await Services.delete('Contact/DeleteFromGroup', model)
        }
        await this.refreshContacts()
      } finally {
        this.loading = false
      }
    },
    openUserCompanyView (companyId) {
      if (this.unpined) {
        window.dispatchEvent(
          new CustomEvent('goToPath', {
            detail: `/company-details/${companyId}`
          })
        )
      } else {
        this.$router.push({ path: `/company-details/${companyId}` })
      }
    },
    openUserProfileView (userId) {
      console.log('otwieraj')
      if (this.unpined) {
        window.dispatchEvent(
          new CustomEvent('goToPath', {
            detail: `/user-profile/${userId}`
          })
        )
      } else {
        this.$router.push({ path: `/user-profile/${userId}` })
      }
    },
    async blockUser () {
      await Services.put('/Chat/BlockUser', {
        userId: this.otherUser.id
      })
      this.$emit('user-blocked', this.currentUserId)
    },
    async unblockUser () {
      await Services.put('/Chat/UnblockUser', {
        userId: this.otherUser.id
      })
      this.$emit('user-unblocked', this.currentUserId)
    }
  }
}
</script>

<style lang="scss">
.window-wrapper-header.new-message .support {
  background: transparent;
  color: black;
}
</style>

<style scoped lang="scss">
.description {
  word-wrap: break-word;
  width: 275px;
}

.support {
  background: linear-gradient(#2d5a40, #333c3c);
  height: 100px;
  color: white;
}

.user-details,
.user-details div {
  position: relative;
  font-size: 11px;
}

.line:after {
  content: "";
  border-top: 2px solid #E6E6E6;
  bottom: 0px;
  left: 0;
  width: 100%;
  position: absolute;
}

.user-details h5 {
  font-size: 12px;
  font-weight: 600;
  color: var(--v-primary-base);
}
</style>
