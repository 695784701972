export const drawer = (state) => {
  return state.drawer
}

export const windows = (state) => {
  return state.windows
}

export const popupWindows = (state) => {
  return state.windows.filter((el) => el.unpined)
}

export const innerWindows = (state) => {
  return state.windows.filter((el) => !el.unpined)
}
export const reviewAdded = (state) => {
  return state.reviewAdded
}
export const isUnpinedWindow = (state) => {
  return state.isUnpinedWindow
}

export const position = (state) => {
  // INIT
  const position = {
    left: null,
    right: null
  }

  if (state.settings.openFromRightToLeft) {
    // SET POSITION
    position.right = 0
    position.left = null

    // CHAT DRAWER
    if (state.drawer) {
      position.right += 300
    }

    // STACK
    if (displayStack(state) > 0 && state.settings.pinToRight) {
      position.right += 205
    }
  } else {
    // SET POSITION
    position.left = 180
    position.right = null

    // STACK
    if (displayStack(state) > 0 && !state.settings.pinToRight) {
      position.left += 200
    }
  }

  return position
}

export const positionStack = (state) => {
  // INIT
  const position = {
    left: null,
    right: null
  }

  if (state.settings.pinToRight) {
    // SET POSITION
    position.right = 0
    position.left = null

    // CHAT DRAWER
    if (state.drawer) {
      position.right += 310
    }
  } else {
    // SET POSITION
    position.left = 0
    position.right = null
  }

  return position
}

export const settings = (state) => {
  return state.settings
}

export const displayStack = (state) => {
  return state.windows.filter((el) => el.unpined).length > 0
}

export const maxChatWindows = (state) => {
  const chatWindowWidth = 340
  const stack = displayStack(state) ? 700 : 500
  return Math.floor((state.browserWidth - stack) / chatWindowWidth) || 1
}

export const lastMessages = (state) => {
  return state.lastMessages
}

export const load = (state) => {
  return state.load
}

export const otherMessages = (state) => {
  return state.otherMessages
}

export const loadOther = (state) => {
  return state.loadOther
}

export const helpMessages = (state) => {
  return state.helpMessages
}

export const loadHelp = (state) => {
  return state.loadHelp
}

export const offers = (state) => {
  return state.offers
}

export const loadOffers = (state) => {
  return state.loadOffers
}

export const threadType = (state) => {
  return state.threadType
}

export const threads = (state) => {
  return state.threads
}

export const loadThreads = (state) => {
  return state.loadThreads
}

export const order = (state) => {
  return state.order
}

export const newMarket = (state) => {
  return state.newMarket
}

export const newFreight = (state) => {
  return state.newFreight
}

export const newOther = (state) => {
  return state.newOther
}

export const enable = (state) => {
  return state.enable
}

export const autostart = (state) => {
  return state.autostart
}

export const notifications = (state) => {
  return state.notifications
}

export const initUnPinedChat = (state) => {
  return state.initUnPinedChat
}
