export const SET_DRAWER = 'SET_DRAWER'
export const SET_SETTINGS = 'SET_SETTINGS'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const NEW_MESSAGE = 'NEW_MESSAGE'
export const SET_BROWSER_WIDTH = 'SET_BROWSER_WIDTH'
export const CLOSE_CONVERSATION = 'CLOSE_CONVERSATION'
export const SET_UNPINED_WINDOW = 'SET_UNPINED_WINDOW'
export const ADD_MESSAGES = 'ADD_MESSAGES'
export const CLEAR_CONVERSATIONS = 'CLEAR_CONVERSATIONS'
export const MARK_AS_READ = 'MARK_AS_READ'
export const ARCHIVE_THREAD = 'ARCHIVE_THREAD'
export const TRANSLATE_MESSAGE = 'TRANSLATE_MESSAGE'
export const ADD_USER_TO_CONVERSATION = 'ADD_USER_TO_CONVERSATION'
export const REVIEW_ADDED = 'REVIEW_ADDED'
export const REFRESH_CONVERSATION = 'REFRESH_CONVERSATION'
export const BLOCK_USER = 'BLOCK_USER'
export const UNBLOCK_USER = 'UNBLOCK_USER'
export const LAST = 'LAST'
export const CLEAR_LAST = 'CLEAR_LAST'
export const ADD_LAST = 'ADD_LAST'
export const REMOVE_LAST = 'REMOVE_LAST'
export const OTHER = 'OTHER'
export const CLEAR_OTHER = 'CLEAR_OTHER'
export const HELP = 'HELP'
export const CLEAR_HELP = 'CLEAR_HELP'
export const OFFERS = 'OFFERS'
export const CLEAR_OFFERS = 'CLEAR_OFFERS'
export const THREAD_TYPE = 'THREAD_TYPE'
export const THREADS = 'THREADS'
export const CLEAR_THREADS = 'CLEAR_THREADS'
export const REMOVE_LAST_OFFER = 'REMOVE_LAST_OFFER'
export const ORDER = 'ORDER'
export const NEW = 'NEW'
export const CLEAR_NEW = 'CLEAR_NEW'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const ENABLE = 'ENABLE'
export const DISABLE = 'DISABLE'
export const MARK_AS_RECEIVED = 'MARK_AS_RECEIVED'
export const MINIMALIZE_WINDOW = 'MINIMALIZE_WINDOW'
export const READ_NEW_MESSAGE = 'READ_NEW_MESSAGE'
export const READ_LAST_MESSAGE = 'READ_LAST_MESSAGE'
export const RESET_STATE = 'RESET_STATE'
export const UPDATE_BLOCKED_SEND_MESSAGE = 'UPDATE_BLOCKED_SEND_MESSAGE'
export const CLOSE_HELPDESK_THREAD = 'CLOSE_HELPDESK_THREAD'
export const USER_STATUS_CHANGED = 'USER_STATUS_CHANGED'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const READ_MSG = 'READ_MSG'
