<template>
  <div class="action-buttons" v-if="!isUnpined">
    <v-btn icon x-small @click.stop="minimalizeWindow" tile>
      <v-icon small dense :color="color">$minimalize</v-icon>
    </v-btn>
    <v-btn icon x-small @click="unpinChat" tile>
      <v-icon small dense :color="color">$maximalize</v-icon>
    </v-btn>
    <v-btn icon class="pa-0" x-small @click="close" tile>
      <v-icon class="pa-0" small dense :color="color">$close</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    currentThreadId: String,
    color: String
  },
  computed: {
    isUnpined () {
      return this.$route.path.includes('unpined-chat')
    }
  },
  methods: {
    close () {
      if (!this.isUnpined) {
        this.$store.dispatch('chat/closeConversation', { conversationId: this.id, currentThreadId: this.currentThreadId })
      } else {
        window.close()
      }
    },
    unpinChat () {
      this.$store.dispatch('chat/unpinWindow', { conversationId: this.id, currentThreadId: this.currentThreadId })
    },
    minimalizeWindow () {
      this.$emit('minimalizeWindow')
    }
  }
}
</script>
<style lang="scss">
.action-buttons .v-btn__content {
  padding: 0 !important;
}
</style>
