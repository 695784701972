<template>
  <div class="w-100 h-100 d-flex justify-center align-center">
    <v-progress-circular
      indeterminate
      color="primary"
      size="100"
    />
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>
