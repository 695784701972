<template>
  <div class="pb-2 shadow-top">
    <template v-if="dealAccepted">
      <template v-if="review">
        <div class="py-1 px-4 deal">
          <h4 class="font-weight-semibold py-2">
            {{ $t('chatWindow.deal.acceptedOffer') }}: {{ dealAccepted.price }} {{ dealCurrency }}
          </h4>
        </div>
        <template v-if="review.state === 1">
          <h5 class="font-weight-semibold text-center py-4">
            {{ $t('reviews.removed') }}
          </h5>
        </template>
        <template v-else>
          <div class="d-flex justify-center align-center py-2">
            <h5 class="primary--text font-weight-semibold mr-2">
              {{ $t('chatWindow.deal.review.ratingIssued') }}
            </h5>
            <v-rating length="5" v-model="review.rate" color="accent" readonly />
          </div>
          <div class="d-flex justify-center">
            <v-btn color="primary" @click="addReview()" depressed>{{ $t('chatWindow.deal.review.showDetails') }}</v-btn>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="py-1 px-4 deal">
          <h4 class="font-weight-semibold">
            {{ $t('chatWindow.deal.acceptedOffer') }}: {{ dealAccepted.price }} {{ dealCurrency }}
          </h4>
          <h4 class="primary--text font-weight-semibold">
            {{ dealAcceptedMessage.reviewAvailability }}
          </h4>
        </div>
        <v-row no-gutters class="my-4">
          <v-col cols="4" offset="4">
            <v-btn color="primary" @click="addReview()" depressed block>{{ $t('chatWindow.deal.review.addRating') }}</v-btn>
          </v-col>
        </v-row>
      </template>
      <ReviewClient v-if="isProvider" ref="review" :orderId="thread.associatedItem" :review="review" />
      <ReviewProvider v-else ref="review" :orderId="thread.associatedItem" :review="review" />
    </template>
    <template v-else>
      <v-form ref="form"  @submit.prevent="submit" v-model="valid">
        <v-row no-gutters class="pt-1">
          <v-col cols="1"></v-col>
          <v-col>
            <label>{{ $t('chatWindow.deal.price') }}</label>
            <v-text-field v-model="price" type="number" hide-details :disabled="(isCurrentUserOrderOwner && !canSendCounterOffer) || loading" dense outlined :rules="[onlyInteger]" />
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row no-gutters class="my-2">
          <v-col cols="1"></v-col>
          <v-col>
            <v-btn v-if="isCurrentUserOrderOwner" :loading="loading" class="mr-1" @click="dealDialog" outlined color="primary" :disabled="!lastOffer">
              {{ $t('chatWindow.deal.acceptOffer') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn v-if="!isCurrentUserOrderOwner" :loading="loading" color="primary" @click="sendOffer" :disabled="price <= 0 || loading" depressed block>
              {{ $t('chatWindow.deal.sendOffer') }}
            </v-btn>
            <v-btn v-else color="primary" @click="sendCounterOffer" class="ml-1" :loading="loading" :disabled="(isCurrentUserOrderOwner && !canSendCounterOffer) || price <= 0" depressed>
              {{ $t('chatWindow.deal.sendCounterOffer') }}
            </v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-form>
      <accept-deal ref="acceptDealDialog" @accept="acceptOffer"></accept-deal>
    </template>
  </div>
</template>

<script>
import Services from '@/services'
import AcceptDeal from './Dialogs/AcceptDeal.vue'
import * as types from '@/types/message-types'
import ReviewClient from '@/views/Reviews/dialogs/ReviewClient.vue'
import ReviewProvider from '@/views/Reviews/dialogs/ReviewProvider.vue'
export default {
  components: { AcceptDeal, ReviewClient, ReviewProvider },
  props: {
    conversationId: String,
    currentThreadId: String,
    otherUserId: String
  },
  computed: {
    window () {
      return this.$store.getters['chat/windows'].find(
        (el) => el.id === this.conversationId && el.currentThreadId === this.currentThreadId
      )
    },
    allCurrency () {
      return this.$store.getters.enums.currency
    },
    conversation () {
      return this.window?.conversation
    },
    thread () {
      return this.conversation?.threads.find(
        (el) => el.id === this.currentThreadId
      )
    },
    offers () {
      return this.thread?.messages.filter((message) => message.type === 4)
    },
    lastOffer () {
      if (!this.offers) {
        return null
      }
      return this.offers[this.offers.length - 1]?.offer
    },
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    isCurrentUserOrderOwner () {
      return this.thread.ownerId === this.currentUserId
    },
    isProvider () {
      if (this.thread.orderProvierId) return this.thread.orderProvierId === this.currentUserId
      else return this.thread.orderClientId !== this.currentUserId
    },
    canSendCounterOffer () {
      if (!this.isCurrentUserOrderOwner) {
        return false
      }

      const messages = this.thread?.messages.filter(
        (message) =>
          message.type === types.OFFER || message.type === types.COUNTEROFFER
      )

      if (!messages || messages.length === 0) {
        return false
      }

      return messages[messages.length - 1].type === types.OFFER
    },
    dealAcceptedMessage () {
      return this.thread?.messages.find((el) => el.type === types.DEAL)
    },
    dealAccepted () {
      return this.thread?.messages.find((el) => el.type === types.DEAL)?.offer
    },
    dealCurrency () {
      if (!this.dealAccepted) {
        return ''
      }
      return this.allCurrency.find((el) => el.id === this.dealAccepted.currency)
        .description
    },
    createReviewValid () {
      const review = this.createReviewModel
      if (review.rate === 5 && review.comment) {
        return true
      }
      if (review.rate === 0 && review.comment) {
        return false
      }
      if (review.rate > 0 && review.rate <= 5) {
        if (!this.isProvider) {
          return (
            this.isValidRate(review.communicationRate) &&
            this.isValidRate(review.punctualityRate) &&
            this.isValidRate(review.serviceQualityRate) &&
            this.isValidRate(review.accordanceWithArrangementsRate) &&
            review.comment
          )
        } else {
          return (
            this.isValidRate(review.communicationRate) &&
            this.isValidRate(review.paymentRate) &&
            this.isValidRate(review.accordanceWithArrangementsRate) &&
            review.comment
          )
        }
      }

      return false
    }
  },
  data () {
    return {
      orderId: null,
      price: 0,
      currency: 0,
      loading: false,
      review: null,
      createReviewModel: {},
      showReviewDetails: false,
      valid: true,
      onlyInteger: (v) => v ? Number.isInteger(Number(v)) ? true : this.$t('validation.onlyInteger') : true
    }
  },
  watch: {
    async dealAccepted (newVal) {
      if (newVal) {
        await this.getReview()
      }
    }
  },
  methods: {
    submit () {
      if (this.isCurrentUserOrderOwner) {
        this.sendCounterOffer()
      } else {
        this.sendOffer()
      }
    },
    async sendOffer () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      try {
        await Services.post('Order/SendOffer', {
          orderId: this.thread.associatedItem,
          price: this.price
        })
      } catch (error) {
        await this.handleDealException(error)
      } finally {
        this.loading = false
      }
    },
    dealDialog () {
      this.$refs.acceptDealDialog.open(this.lastOffer)
    },
    async acceptOffer (offer) {
      this.loading = true
      try {
        await Services.post('Order/Deal', {
          orderId: this.thread.associatedItem,
          userId: this.otherUserId,
          offerId: offer.offerId
        })
      } catch (error) {
        await this.handleDealException(error)
      } finally {
        this.$refs.acceptDealDialog.close()
        this.loading = false
      }
    },
    async sendCounterOffer () {
      this.loading = true
      try {
        await Services.post('Order/SendCounterOffer', {
          orderId: this.thread.associatedItem,
          price: this.price,
          userId: this.otherUserId
        })
      } catch (error) {
        await this.handleDealException(error)
      } finally {
        this.loading = false
      }
    },
    async handleDealException (error) {
      switch (error.exceptionType) {
        case 3:
          await this.$store.dispatch('refreshConversation', { conversationId: this.conversationId, threadId: this.thread.id })
      }
    },
    async getReview () {
      if (this.dealAccepted) {
        try {
          this.loading = true
          const { data } = await Services.get('Reviews/GetReviewByOrder', {
            orderId: this.thread.associatedItem
          })
          if (data) {
            this.review = data
          } else {
            this.review = null
          }
        } finally {
          this.loading = false
        }
      }
    },
    async sendReview () {
      try {
        this.loading = true
        const url = this.isProvider ? 'Reviews/ReviewClient' : 'Reviews/ReviewProvider'
        await Services.post(url, {
          ...this.createReviewModel,
          orderId: this.thread.associatedItem
        })
        await this.getReview()
      } finally {
        this.loading = false
      }
    },
    isValidRate (rate) {
      return rate > 0 && rate <= 5
    },
    addReview () {
      this.$refs.review.show()
    }
  },
  async mounted () {
    await this.getReview()
  }
}
</script>

<style></style>
