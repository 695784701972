<template>
  <v-dialog
    :value="getNotificationDialog.open"
    persistent
    max-width="360"
  >
    <v-card class="d-flex flex-column align-center pa-6 rounded-lg">
      <v-img
        :aspect-ratio="16/9"
        width="50%"
        contain
        :src="getNotificationDialog.type === 'error' ? require('@/assets/images/notificationDialog/error-notification.svg') : require('@/assets/images/notificationDialog/success-notification.svg')"
      ></v-img>
      <v-card-text class="pt-5 text-center">{{ getNotificationDialog.text }}</v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          @click="closeDialog"
        >
          {{ $t('notification.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Notification',
  computed: {
    ...mapGetters({
      getNotificationDialog: 'getNotificationDialog'
    })
  },
  methods: {
    ...mapActions({
      closeNotificationDialog: 'closeNotificationDialog'
    }),
    async closeDialog () {
      await this.getNotificationDialog.action()
      this.closeNotificationDialog()
    }
  }
}
</script>
