<template>
  <div
    class="window-wrapper d-flex flex-column"
    :class="getWrapperClasses"
    v-if="conversation"
    @click.stop="open"
  >
    <div
      class="window-wrapper-header"
      :class="windowWrapperHeaderClass"
    >
      <CooperationStatus v-if="!isSupportThread" :users="users" />
      <UserDetails
        :users="users"
        :unpined="unpined"
        :conversationId="conversationId"
        :currentThreadId="currentThreadId"
        :conversationType="conversation.type"
        :blockedBy="conversation.blockedBy"
        :isSupportThread="isSupportThread"
        :hasNewMessage="hasNewMessage"
        @minimalizeWindow="minimalizeWindow"
        @user-blocked="blockUser"
        @user-unblocked="unblockUser"
      />
      <CooperationSuspended v-if="pausedCompanyCooperationStatus" />
    </div>
    <MainContent
      ref="mainContent"
      v-show="!minimalize"
      :conversationId="conversationId"
      :currentThreadId="currentThreadId"
      :isSupportThread="isSupportThread"
      :isOpen="!minimalize"
    />

    <div v-if="currentThreadId" v-show="!minimalize">
      <div v-if="conversation.blockedBy || blockedUserInSystem" class="d-flex align-center justify-space-between px-4 py-2 shadow-top">
        <h5 class="primary--text font-weight-semibold">
          <template v-if="blockedUserInSystem">{{ $t('blockedUsersList.blockedInSystem') }}</template>
          <template v-else-if="conversation.blockedBy === this.currentUserId">{{ $t('blockedUsersList.covnersationBlockedByYou') }}</template>
          <template v-else>{{ $t('blockedUsersList.covnersationBlockedByUser') }}</template>
        </h5>
        <div class="accent pa-1 rounded">
          <v-icon color="primary" size="24">$ban</v-icon>
        </div>
      </div>
      <input-area
        v-if="!conversation.blockedBy && !blockedUserInSystem"
        v-show="!minimalize"
        ref="inputArea"
        :currentThreadId="currentThreadId"
        :conversationId="conversationId"
        :users="users"
        :isUnpined="unpined"
        :isSupportThread="isSupportThread"
        :threadType="currentThread.type"
        :blocked-send-message="currentWindow.blockedSendMessage"
      ></input-area>
    </div>
  </div>
</template>

<script>
import CooperationStatus from './CooperationStatus.vue'
import InputArea from './InputArea.vue'
import MainContent from './MainContent.vue'
import UserDetails from './UserDetails.vue'
import * as conversationThreadTypes from '@/types/conversation-thread-type'
import * as companyCooperationStatus from '@/types/company-cooperation-status'
import CooperationSuspended from '@/chat/Window/CooperationSuspended'
import { NEW_DEAL_MESSAGE, NEW_MESSAGE } from '@/types/new-message-type'
export default {
  components: {
    UserDetails,
    MainContent,
    InputArea,
    CooperationStatus,
    CooperationSuspended
  },
  props: {
    conversationId: String,
    currentThreadId: String,
    unpined: Boolean,
    isArchive: Boolean
  },
  data () {
    return {
      hasNewMessage: false,
      newMessageType: NEW_MESSAGE
    }
  },
  watch: {
    otherUser (newVal) {
      if (newVal) {
        this.setUnpinedTitle(newVal)
      }
    },
    currentWindow: {
      handler (newValue) {
        this.hasNewMessage = newValue.hasNewMessage
        this.newMessageType = newValue.newMessageType
        this.blockedSendMessage = newValue.blockedSendMessage
      },
      deep: true
    }
  },
  computed: {
    currentThread () {
      return this.conversation?.threads.find(
        (el) => el.id === this.currentThreadId
      )
    },
    blockedUserInSystem () {
      return !!this.conversation?.users.find(
        (el) => el.user.status === 2
      )
    },
    currentThreadType () {
      return this.currentThread?.type
    },
    isSupportThread () {
      return this.currentThreadType === conversationThreadTypes.OthersHelpdesk
    },
    currentWindow () {
      return this.$store.getters['chat/windows'].find(
        (el) => el.id === this.conversationId && el.currentThreadId === this.currentThreadId
      )
    },
    conversation () {
      return this.currentWindow?.conversation
    },
    users () {
      return this.conversation?.users
    },
    otherUser () {
      return this.users?.find((el) => el.user.id !== this.currentUserId)
    },
    getWrapperClasses () {
      let result = ''
      if (this.minimalize) {
        if (this.isSupportThread) {
          result += ' minimalize-support'
        } else {
          result += ' minimalize'
        }
      }
      if (this.unpined) {
        result += ' unpined'
      }
      if (this.isArchive) {
        result += ' archive'
      }
      return result
    },
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    pausedCompanyCooperationStatus () {
      return this.conversation.companyCooperationStatus === companyCooperationStatus.PAUSED
    },
    minimalize: {
      get () {
        return this.currentWindow ? this.currentWindow.minimalize && !this.unpined : false
      },
      set (value) {}
    },
    isUnpinedChat () {
      return this.$route.name === 'UnpinedChat'
    },
    windowWrapperHeaderClass () {
      return {
        'new-message': !this.isUnpinedChat && this.hasNewMessage && this.newMessageType === NEW_MESSAGE,
        'new-deal-message': !this.isUnpinedChat && this.hasNewMessage && this.newMessageType === NEW_DEAL_MESSAGE
      }
    }
  },
  methods: {
    focus () {
      this.$refs.inputArea.focus()
    },
    minimalizeWindow () {
      // TODO: REMOVE THIS LOG
      console.log('minimalizeWindow - this.minimalize', this.minimalize)
      if (this.minimalize) {
        this.$store.commit('chat/READ_NEW_MESSAGE', { conversationId: this.conversationId, currentThreadId: this.currentThreadId })
      }
      this.$store.commit('chat/MINIMALIZE_WINDOW', {
        conversationId: this.conversationId,
        currentThreadId: this.currentThreadId,
        value: !this.minimalize
      })
    },
    open () {
      // TODO: REMOVE THIS LOG
      console.log('open - this.minimalize', this.minimalize)
      if (this.minimalize) {
        this.$store.commit('chat/MINIMALIZE_WINDOW', {
          conversationId: this.conversationId,
          currentThreadId: this.currentThreadId,
          value: false
        })
        this.$store.commit('chat/READ_NEW_MESSAGE', { conversationId: this.conversationId, currentThreadId: this.currentThreadId })
      }
    },
    blockUser (event) {
      this.$store.commit('chat/BLOCK_USER', { conversationId: this.conversationId, userId: event })
    },
    unblockUser () {
      this.$store.commit('chat/UNBLOCK_USER', { conversationId: this.conversationId })
    },
    setUnpinedTitle (user) {
      if (!user) {
        return
      }
      document.title = user.user.fullName
    }
  },
  mounted () {
    this.setUnpinedTitle(this.otherUser)
  }
}
</script>

<style scope>
.window-wrapper {
  transition: height 0.2s ease;
}

.window-wrapper.archive,
.window-wrapper.unpined {
  width: 100%;
  height: 100%;
  min-width: 340px;
  min-height: 580px;
  margin: 0;
  padding: 0;
  border: none;
}

.window-wrapper {
  width: 340px;
  height: 580px;
  border: solid var(--v-primary-base) 1px;
  border-radius: 5px;
  background: rgb(250, 250, 250);
  /*box-shadow: 0 0 3px grey;*/
}
.window-wrapper.minimalize {
  height: 104px;
  cursor: pointer;
}

.window-wrapper.minimalize-support {
  height: 56px;
  cursor: pointer;
}
</style>
