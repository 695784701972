import * as signalR from '@microsoft/signalr'
import store from '@/store'
import * as types from '@/store/modules/notifications/notifications-mutation-types'
import { log } from '@/utils/logger'

const lang = localStorage.getItem('lang') || 'pl-PL'

const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${process.env.VUE_APP_BASE}notificationHub?Language=${lang}`, {
    accessTokenFactory: () => localStorage.getItem('token')
  })
  .withAutomaticReconnect()
  .build()

export const notificationsStart = async () => {
  if (store.getters.isDriver) {
    return
  }

  try {
    await connection.start()
    const notifications = await connection.invoke('GetNotifications')
    log('NOTIFICATIONHUB - GetNotifications', notifications)
    await store.commit(`notification/${types.SET_NOTIFICATIONS}`, notifications)
  } catch (e) {
    console.error(e.toString())
  }
}

export const notificationsStop = async () => {
  try {
    await connection.stop()
  } catch (e) {
    console.error(e.toString())
  }
}

export const markAsRead = async (notificationId) => {
  try {
    await connection.invoke('MarkAsRead', notificationId)
    log('NOTIFICATIONHUB - MarkAsRead', notificationId)
    await store.commit(`notification/${types.MARK_AS_READ_NOTIFICATION}`, notificationId)
  } catch (e) {
    console.error(e.toString())
  }
}

connection.on('ReceiveSystemNotification', async (notification) => {
  log('NOTIFICATIONHUB - ReceiveSystemNotification', notification)
  await store.dispatch('notification/addNewNotification', notification)
})
