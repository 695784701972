<template>
<div>
  <div :class="classes" class="d-flex">
    <div class="pa-2 rounded text-wrap text-wrapper" :class="{ 'message-image': previewImage, 'ml-auto': isCurrentUserSender && isDeleted, 'ml-1': !isCurrentUserSender }">
      <template v-if="isDeleted">
        <span class="font-italic">
          [{{ $t('chatWindow.messageDeleted') }}] [{{ $t('chatWindow.file') }}]
        </span>
      </template>
      <template v-else>
        <div class="d-inline-flex">
          <div @click="fileClick(file)" v-for="(file, index) in files" :key="index" class="cursor-pointer">
            <v-img v-if="file.type === 'IMAGE'" contain :src="file.url" />
            <div v-else>
              <v-icon small class="mr-1">uil uil-import</v-icon>
              <span class="text-decoration-underline">{{ file.customName + '.' + file.extension }}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-if="!isDeleted" class="d-flex align-center" :class="isCurrentUserSender ? 'order-first ml-auto' : ''">
      <v-btn v-if="showMessageOptions" icon small @click="openMenu">
        <v-icon color="separator">uil uil-ellipsis-v</v-icon>
      </v-btn>
      <v-menu v-model="showMenu" absolute offset-y :position-x="menuPosition.x" :position-y="menuPosition.y" class="menu">
        <v-list class="py-0">
          <v-list-item v-if="canTranslate" dense @click="translateMessage">
            <v-icon small class="mr-2">$translate</v-icon>
            {{ $t('chatWindow.translateMessage') }}
          </v-list-item>
          <v-list-item v-if="canDelete" dense @click="deleteMessage">
            <v-icon small>uil uil-trash</v-icon>
            {{ $t('chatWindow.deleteMessage') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
  <v-dialog v-model="imageDialog" scrollable max-width="1200">
    <v-card v-if="popupFile">
      <v-card-title class="py-2 pr-2">
        <h5 class="primary--text">{{ popupFile.customName }}</h5>
        <v-spacer />
        <v-btn class="mr-2" color="primary" @click="download(popupFile)" depressed><v-icon class="mr-2"> uil uil-import</v-icon>{{ $t('common.download') }}</v-btn>
        <v-btn icon @click="imageDialog = false"><v-icon>$close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="d-flex h-100">
        <v-img
          v-if="popupFile.url"
          class="ma-auto"
          :src="popupFile.url"
        ></v-img>

        <v-progress-circular
          class="ma-auto"
          v-else
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import Services from '@/services'
import { deleteMessage } from '@/signalr/chat.js'
export default {
  components: {},
  props: {
    value: Object,
    threadId: String,
    allowTranslate: Boolean,
    idToUseAsCurrentUser: String
  },
  data () {
    return {
      files: [],
      imageDialog: false,
      popupFile: null,
      previewImage: false,
      showMenu: false,
      menuPosition: {
        x: 0,
        y: 0
      }
    }
  },
  computed: {
    currentUserMessage () {
      return this.value?.users?.find((el) => el.id === this.currentUserId) ?? {}
    },
    othreUserMessage () {
      return this.value?.users?.find((el) => el.id !== this.currentUserId) ?? {}
    },
    isCurrentUserSender () {
      return this.currentUserId === this.value.sender
    },
    currentUserId () {
      if (this.idToUseAsCurrentUser) {
        return this.idToUseAsCurrentUser
      }
      return this.$store.getters.userCommonData.id
    },
    classes () {
      let classes = 'message'
      if (this.isCurrentUserSender) {
        classes += ' current-user-sender'
      }
      return classes
    },
    isTranslated () {
      return !!this.value.translation
    },
    isDeleted () {
      return this.value.deleted
    },
    canDelete () {
      return this.isCurrentUserSender
    },
    canTranslate () {
      return !this.isCurrentUserSender && !this.value.translation
    },
    showMessageOptions () {
      return this.canDelete
    },
    splitedValue () {
      const value = this.isTranslated ? this.value.translation : this.value.text
      return value.toString().split('\n')
    }
  },
  methods: {
    async fileClick (file) {
      if (file.type === 'IMAGE') {
        const response = await Services.getFile('Chat/GetChatFile', {
          threadId: this.threadId,
          fileName: file.originalName
        })
        const blob = new Blob([response.data])
        const fileUrl = URL.createObjectURL(blob)
        this.imageDialog = true
        this.popupFile = { ...file, url: fileUrl }
      } else {
        await this.download(file)
      }
    },
    async download (file) {
      const response = await Services.getFile('Chat/GetChatFile', {
        threadId: this.threadId,
        fileName: file.originalName
      })
      if (file.extension === 'pdf') {
        const document = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(document)
        window.open(fileURL)
      } else {
        const blob = new Blob([response.data])
        const fileUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = fileUrl
        link.download = file.customName + '.' + file.extension
        link.click()
        URL.revokeObjectURL(link.href)
      }
    },
    async translateMessage () {
      const response = await Services.get('ExternalApi/Translate', {
        text: this.value.text
      })
      this.$store.dispatch('chat/translateMessage', {
        id: this.value.id,
        text: response.data.data.translations[0].translatedText
      })
    },
    deleteMessage () {
      deleteMessage(this.threadId, this.value.id)
    },
    openMenu (e) {
      this.showMenu = true
      this.menuPosition.x = e.clientX
      this.menuPosition.y = e.clientY
    }
  },
  mounted () {
    this.value.files.forEach(async (file) => {
      const extension = file.originalName.split('.').pop()
      const newFile = {
        ...file,
        extension,
        url: '',
        type: ''
      }
      if ('png jpg jpeg'.includes(extension)) {
        newFile.type = 'IMAGE'
        this.previewImage = true
      } else if (extension.includes('pdf')) {
        newFile.type = 'PDF'
      } else if (extension.includes('XLS')) {
        newFile.type = 'XLS'
      } else {
        newFile.type = 'OTHER'
      }
      if (newFile.type === 'IMAGE') {
        const response = await Services.getFile('Chat/GetChatFile', {
          threadId: this.threadId,
          fileName: file.thumbnailName
        })
        const blob = new Blob([response.data])
        newFile.url = URL.createObjectURL(blob)
      }

      this.files.push(newFile)
    })
  }
}
</script>

<style scoped>
.message-image {
  min-height: 108px;
  max-height: 108px;
  height: 108px;
}
</style>
