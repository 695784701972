<template>
  <v-tooltip bottom style="z-index: 10000">
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <v-icon :color="color.first" small style="right: -13px">uil uil-check</v-icon>
        <v-icon :color="color.second" small>uil uil-check</v-icon>
      </div>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    read: Boolean,
    received: Boolean
  },
  computed: {
    color () {
      if (this.read) {
        return {
          first: 'accent',
          second: 'accent'
        }
      } else if (this.received) {
        return {
          first: 'accent',
          second: 'grey lighten-1'
        }
      } else {
        return {
          first: 'grey lighten-1',
          second: 'grey lighten-1'
        }
      }
    },
    tooltip () {
      if (this.read) {
        return 'Odczytano'
      } else if (this.received) {
        return 'Odebrano'
      }
      return 'Wysłano'
    }
  }
}
</script>
