import store from '@/store'
import { CLEAR_TITLE_NOTIFICATION_INTERVAL, SET_TITLE_NOTIFICATION_INTERVAL } from '@/store/mutation-types'
import { log } from '@/utils/logger'
import * as soundTypes from '@/types/sound-types'

const signalR = require('@microsoft/signalr')

const lang = localStorage.getItem('lang') || 'pl-PL'

const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${process.env.VUE_APP_BASE}chatHub?Language=${lang}`, {
    accessTokenFactory: () => localStorage.getItem('token')
  })
  .withAutomaticReconnect()
  .build()

connection.on(
  'ReceiveMessage',
  async ({ userId, message, conversationId, threadId, threadType, orderId }) => {
    log('CHATHUB - ReceiveMessage', userId, message, conversationId, threadId, threadType, orderId)
    const currentUserId = store.getters.userCommonData.id

    if (userId !== currentUserId) {
      store.commit(SET_TITLE_NOTIFICATION_INTERVAL)
      if (message.type === 2 && store.state.chat.settings.acceptOfferSound) {
        await store.dispatch('notification/playSound', soundTypes.PRISTINE)
      } else if (message.type === 4 && store.state.chat.settings.newOfferSound) {
        await store.dispatch('notification/playSound', soundTypes.PRISTINE)
      } else if (store.state.chat.settings.newMessageSound) {
        await store.dispatch('notification/playSound', soundTypes.PRISTINE)
      }
    }

    await store.dispatch('chat/newMessage', {
      userId,
      message,
      conversationId,
      threadId
    })

    await store.dispatch('chat/message', {
      threadType,
      orderId,
      threadId,
      currentUserId,
      conversationId
    })

    await connection.invoke('MarkAsReceived', threadId, message.id)
    store.commit('chat/UPDATE_BLOCKED_SEND_MESSAGE', { conversationId, currentThreadId: threadId, blockedSendMessage: false })
  }
)

connection.on('MessageReceived', (userId, messageId, threadId) => {
  log('CHATHUB - MessageReceived', userId, messageId, threadId)
  store.dispatch('chat/markAsReceived', { userId, messageId, threadId })
})

connection.on('MessageDeleted', (messageId, threadId) => {
  log('CHATHUB - MessageDeleted', messageId, threadId)
  store.dispatch('chat/deleteMessage', { threadId, messageId })
  store.dispatch('chat/deleteMsgSidebar', { messageId })
})

connection.on('MessageRead', (userId, messageId, threadId) => {
  log('CHATHUB - MessageRead', userId, messageId, threadId)
  store.dispatch('chat/markAsRead', { userId, threadId, messageId })
})

connection.on('UserAddedToConversation', (conversationId, user) => {
  log('CHATHUB - UserAddedToConversation', conversationId, user)
  store.dispatch('chat/addUserToConversation', { conversationId, user })
})

connection.on('ContactChange', (userId, showCooperationState, cooperationState) => {
  log('CHATHUB - ContactChange', userId, showCooperationState, cooperationState)
  store.dispatch('chat/contactChange', { userId, showCooperationState, cooperationState })
})

connection.on('UserBlocked', (userId, conversationId) => {
  log('CHATHUB - UserBlocked', userId, conversationId)
  store.dispatch('chat/blockUser', { userId, conversationId })
})

connection.on('UserUnblocked', (userId, conversationId) => {
  log('CHATHUB - UserUnblocked', userId, conversationId)
  store.dispatch('chat/unblockUser', { userId, conversationId })
})

connection.on('ThreadArchived', async (threadId) => {
  log('CHATHUB - ThreadArchived', threadId)
  await store.dispatch('chat/archiveThread', { threadId })
})

connection.on('UserStatusChanged', async (userId, userStatusType) => {
  log('CHATHUB - UserStatusChanged', userId, userStatusType)
  await store.dispatch('chat/userStatusChanged', { userId, userStatusType })
})

connection.on('ReviewAdded', async (orderId) => {
  log('CHATHUB - ReviewAdded', orderId)
  await store.dispatch('chat/reviewAdded', { orderId })
})

export const start = async () => {
  if (store.getters.isDriver) {
    return
  }
  await connection.start()
  await markAllThreadsAsReceived()
}

export const stop = () => {
  connection.stop()
}

export const listenEvent = (methodName, callback) => {
  connection.on(methodName, callback)
}

export const startConversation = async (receiverId, threadAssociatedItem) => {
  const response = await connection.invoke(
    'StartPrivateConversation',
    receiverId,
    threadAssociatedItem
  )
  log('CHATHUB - StartPrivateConversation', response)
  let threadId = null
  if (threadAssociatedItem) {
    const thread = response.threads.find(
      (el) => el.associatedItem === threadAssociatedItem
    )
    if (thread) {
      threadId = thread.id
    }
  }
  store.dispatch('chat/openConversation', { conversationId: response.id, threadId: threadId })
}

export const sendMessage = async (conversationId, threadId, message) => {
  log('CHATHUB - sendMessage', threadId, message)
  store.commit('chat/UPDATE_BLOCKED_SEND_MESSAGE', { conversationId, currentThreadId: threadId, blockedSendMessage: true })
  await connection.invoke('SendMessage', threadId, message)
}

export const markAsRead = async (threadId, messageId) => {
  log('CHATHUB - markAsRead', threadId, messageId)
  if (threadId && messageId) {
    await connection.invoke('MarkAsRead', threadId, messageId)
    store.commit(CLEAR_TITLE_NOTIFICATION_INTERVAL)
  }
}

export const startHelpDeskChat = async () => {
  const response = await connection.invoke('StartHelpDeskChat')
  log('CHATHUB - startHelpDeskChat', response)
  store.dispatch('chat/openConversation', { conversationId: response.conversationId, threadId: response.threadId })
}

export const setState = async (state) => {
  log('CHATHUB - setState', state)
  await connection.invoke('SetState', state)
}

export const setDescription = async (description, show) => {
  log('CHATHUB - setDescription', description, show)
  await connection.invoke('SetDescription', description, show)
}

export const deleteMessage = async (threadId, messageId) => {
  log('CHATHUB - deleteMessage', threadId, messageId)
  await connection.invoke('DeleteMessage', threadId, messageId)
}

export const sendGeolocation = async (threadId, posision, message) => {
  log('CHATHUB - sendGeolocation', threadId, posision, message)
  return await connection.invoke('SendMessageWithGeolocalisation', threadId, message, posision)
}

export const markAllThreadsAsReceived = async () => {
  await connection.invoke('MarkAllThreadsAsReceived')
  log('CHATHUB - markAllThreadsAsReceived')
}
