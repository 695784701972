import state from './chat-state'
import * as getters from './chat-getters'
import mutations from './chat-mutations'
import * as actions from './chat-actions'

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
