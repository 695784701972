<template>
  <v-snackbar
    v-model="getSnackBar.open"
    :color="getSnackBar.type"
    :timeout="getSnackBar.timeout"
    right
    bottom
  >
    {{ getSnackBar.text }}
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Notification',
  computed: {
    ...mapGetters({
      getSnackBar: 'getSnackBar'
    })
  }
}
</script>
