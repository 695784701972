<template>
  <v-card class="mx-auto pa-2" style="max-height: 95vh" width="260" tile>
    <v-list class="py-0">
      <v-list-item dense>
        <v-list-item-title class="font-weight-bold primary--text body-1">
          {{ $t('chatMenu.status') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-for="status in cooperation" :key="status.id" @click="setStatus(status.id)" dense>
        <v-avatar :color="status.color" class="mr-2 elevation-2" size="12" />
        <div :class="(user && user.onlineStatus == status.id) ? 'font-weight-bold' : ''">{{ userOnlineStatusTypes[status.id].description }}</div>
      </v-list-item>
    </v-list>
    <v-divider class="ma-3" />
    <v-list class="py-0">
      <v-list-item dense>
        <v-list-item-title class="font-weight-bold primary--text body-1">
          {{ $t('chatMenu.desc') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="user.chatDescription.length > 0 && !isEdit" dense>
        <v-list-item-title class="font-italic">
          {{ user.chatDescription }}
        </v-list-item-title>
      </v-list-item>
      <!-- edit -->
      <v-list-item v-if="isEdit">
        <v-textarea v-model="user.chatDescription" dense flat no-resize outlined rows="2" counter="100"></v-textarea>
      </v-list-item>
      <v-list-item v-if="isEdit" @click="saveDescription" dense>
        <v-list-item-title class="pointer">
          {{ $t('chatMenu.save') }}
        </v-list-item-title>
      </v-list-item>
      <!-- end edit -->
      <v-list-item v-if="user.chatDescription.length > 0 && !isEdit" @click="isEdit = true" dense>
        <v-list-item-title>{{ $t('chatMenu.edit') }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-else-if="!isEdit" link @click="isEdit = true" dense>
          <v-list-item-title>{{ $t('chatMenu.set') }}</v-list-item-title>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-title>{{ $t('chatMenu.show') }}</v-list-item-title>
        <v-list-item-action class="my-1">
          <v-switch v-model="user.showChatDescription" @change="saveDescription" color="primary" dense></v-switch>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider v-if="$store.getters.isMarketUser" class="ma-3" />
    <v-list v-if="$store.getters.isMarketUser" class="py-0">
      <v-list-item dense>
        <v-list-item-title class="font-weight-bold primary--text body-1">
          {{ $t('chatMenu.teaser') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="!isEditTeaser && chatTeaserActive" dense>
        <v-list-item-title class="font-italic">{{ chatTeaser }}</v-list-item-title>
      </v-list-item>
      <!-- edit -->
      <v-list-item v-if="isEditTeaser" dense>
        <v-textarea v-model="chatTeaser" dense flat no-resize outlined rows="2" counter="100"></v-textarea>
      </v-list-item>
      <v-list-item v-if="isEditTeaser" dense>
        <v-list-item-title>{{ $t('chatMenu.teaserOn') }}</v-list-item-title>
        <v-list-item-action class="my-1">
          <v-switch v-model="chatTeaserActive" :disabled="!chatTeaser.length > 0" color="primary" dense></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="isEditTeaser" :disabled="!chatTeaser.length > 0" @click="saveTeaser" dense>
        <v-list-item-title class="pointer">{{ $t('chatMenu.teaserSave') }}</v-list-item-title>
      </v-list-item>
      <!-- end edit -->
      <v-list-item v-if="chatTeaserActive && !isEditTeaser" @click="isEditTeaser = true" dense>
        <v-list-item-title>{{ $t('chatMenu.teaserEdit') }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-else-if="!isEditTeaser" link @click="isEditTeaser = true" dense>
        <v-list-item-title>{{ $t('chatMenu.teaserSet') }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider class="ma-3" />
    <v-list class="py-0">
      <v-list-item dense>
        <v-list-item-title class="font-weight-bold primary--text body-1">
          {{ $t('chatMenu.sound') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-title>{{ $t('chatMenu.msg') }}</v-list-item-title>
        <v-list-item-action class="my-1">
          <v-switch v-model="soundSettings.newMessageSound" @change="saveSoundSettings" color="primary" dense></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-title>{{ $t('chatMenu.offers') }}</v-list-item-title>
        <v-list-item-action class="my-1">
          <v-switch v-model="soundSettings.newOfferSound" @change="saveSoundSettings" color="primary" dense></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-title>{{ $t('chatMenu.myOffer') }}</v-list-item-title>
        <v-list-item-action class="my-1">
          <v-switch v-model="soundSettings.acceptOfferSound" @change="saveSoundSettings" color="primary" dense></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-title>{{ $t('chatMenu.newInFilter') }}</v-list-item-title>
        <v-list-item-action class="my-1">
          <v-switch v-model="soundSettings.newOfferInFilterSound" @change="saveSoundSettings" color="primary" dense></v-switch>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider class="ma-3" />
    <v-list class="pt-0">
      <v-list-item dense>
        <v-list-item-title class="font-weight-bold primary--text body-1">
          {{ $t('chatMenu.chat') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-title>{{ $t('chatMenu.dock') }}</v-list-item-title>
        <v-list-item-action class="my-1">
          <v-switch :input-value="dockChat" @change="toggleDockChat" color="primary" dense></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-title>{{ $t('chatMenu.start') }}</v-list-item-title>
        <v-list-item-action class="my-1">
          <v-switch :input-value="autostartChatEnable" @change="toggleAutoStartChat" :disabled="dockChat" color="primary" dense></v-switch>
        </v-list-item-action>
      </v-list-item>
    </v-list>
<!--  TODO: REMOVE THIS COMMENT  -->
<!--    <v-divider></v-divider>-->
<!--    <v-list>-->
<!--      <v-list-item dense>-->
<!--        <v-list-item-title>-->
<!--          {{ $t('chatMenu.enable') }}-->
<!--        </v-list-item-title>-->
<!--        <v-list-item-action class="my-1">-->
<!--          <v-switch-->
<!--            color="primary"-->
<!--            :input-value="chatEnable"-->
<!--            @change="toggleChat"-->
<!--          ></v-switch>-->
<!--        </v-list-item-action>-->
<!--      </v-list-item>-->
<!--    </v-list>-->
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import * as userStatus from '@/types/user-status.js'
import * as colors from '@/types/status-colors.js'
import { setState, setDescription } from '@/signalr/chat'
import Service from '@/services'
export default {
  name: 'ChatMenu',
  data () {
    return {
      loading: false,
      isEdit: false,
      isEditTeaser: false,
      statusId: 1,
      chatDescription: '',
      chatTeaser: '',
      chatTeaserActive: false,
      cooperation: [
        {
          id: userStatus.ONLINE,
          color: colors.ONLINE
        },
        {
          id: userStatus.BERIGHTBACK,
          color: colors.BERIGHTBACK
        },
        {
          id: userStatus.BUSY,
          color: colors.BUSY
        },
        {
          id: userStatus.INVISIBLE,
          color: colors.OFFLINE
        }
      ],
      soundSettings: {}
    }
  },
  computed: {
    // chatEnable () {
    //   return this.$store.getters['chat/enable']
    // },
    autostartChatEnable () {
      return this.$store.getters.userCommonData.launchChatAfterStart
    },
    dockChat () {
      return this.$store.getters.userCommonData.dockChat
    },
    userOnlineStatusTypes () {
      return this.$store.getters.enums.userOnlineStatusTypes
    },
    ...mapGetters({
      users: 'users/users'
    }),
    currentUser () {
      return this.$store.getters.userCommonData
    },
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    user () {
      return this.users.find((user) => user.id === this.currentUserId)
    }
  },
  async created () {
    await this.fetchSoundData()
    this.chatTeaser = JSON.parse(JSON.stringify(this.currentUser.chatTeaser))
    this.chatTeaserActive = JSON.parse(JSON.stringify(this.currentUser.chatTeaserActive))
  },
  methods: {
    async fetchSoundData () {
      const { data } = await Service.get('Chat/GetSoundSettings')
      this.soundSettings = data
    },
    async setStatus (status) {
      this.loading = true

      try {
        await setState(status)
        this.$emit('update')
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('chatMenu.successNotification')
        )
      } catch (e) {}

      this.loading = false
    },
    async saveDescription () {
      this.loading = true

      if (this.user.chatDescription.length > 100) {
        await this.$store.dispatch(
          'setErrorNotification',
          this.$t('chatMenu.rules')
        )
        return
      }

      try {
        await setDescription(
          this.user.chatDescription.trim(),
          this.user.showChatDescription
        )
        this.$emit('update')
        this.isEdit = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('common.confirmOperation')
        )
      } catch (e) {}

      this.loading = false
    },
    async saveTeaser () {
      this.loading = true

      if (this.chatTeaser > 100) {
        await this.$store.dispatch(
          'setErrorNotification',
          this.$t('chatMenu.rules')
        )
        return
      }

      try {
        await Service.put('Chat/UpdateChatTeaser ', {
          content: this.chatTeaser
        })
        await Service.put('Chat/UpdateChatTeaserSettings', {
          active: this.chatTeaserActive
        })
        this.isEditTeaser = false
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('common.confirmOperation'))
      } catch (e) {}
      this.loading = false
    },
    async saveSoundSettings () {
      this.loading = true
      try {
        await Service.put('Chat/UpdateSoundSettings', {
          newMessageSound: this.soundSettings.newMessageSound,
          newOfferSound: this.soundSettings.newOfferSound,
          acceptOfferSound: this.soundSettings.acceptOfferSound,
          newOfferInFilterSound: this.soundSettings.newOfferInFilterSound
        })
        await this.$store.dispatch('chat/fetchSettings')
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('common.confirmOperation'))
      } catch (e) {}

      this.loading = false
    },
    async toggleDockChat (value) {
      try {
        await Service.put('/Chat/UpdateDockChatSetting', {
          isActive: value
        })
      } catch (e) {
        console.error(e)
      }
      await this.$store.dispatch('fetchUserCommonData')
      await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
    },
    async toggleAutoStartChat (value) {
      try {
        await Service.post('/Chat/UpdateLaunchChatAfterStart', {
          active: value
        })
      } catch (e) {
        console.error(e)
      }
      await this.$store.dispatch('fetchUserCommonData')
      await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
    }
    // async toggleChat (value) {
    //   if (value) {
    //     await this.$store.dispatch('chat/enableChat')
    //   } else {
    //     await this.$store.dispatch('chat/disableChat')
    //   }
    //   await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
    // }
  }
}
</script>
<style>
.theme--light.v-input--switch .v-input--switch__thumb {
  color: var(--v-primary-base) !important;
}

.v-input--switch.v-input--dense .v-input--switch__thumb {
  width: 12px !important;
  height: 12px !important;
}

.v-input--switch__thumb {
  top: calc(50% - 7px) !important
}

.v-input--switch.v-input--is-dirty .v-input--switch__thumb {
  transform: translate(18px, 0) !important;
}

.v-input--switch.v-input--dense .v-input--switch__track {
  height: 10px !important;
  width: 26px !important;
}

.v-input--switch__track {
  top: calc(50% - 6px) !important
}
</style>
