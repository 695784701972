<template>
  <div
    style="background-color: #b40200"
    class="d-flex justify-space-between white--text font-weight-light px-6 py-1"
  >
    <span style="font-size: 9px"
      >{{ $t("chatSidebar.offline") }}</span
    >
  </div>
</template>
<script>
export default {
  name: 'ChatOffline',
  data () {
    return {
    }
  }
}
</script>
