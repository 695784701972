import store from '@/store'
import i18n from '@/i18n'
import { useFavicon } from '@vueuse/core'

export const guardLogin = (to, from, next) => {
  if (!localStorage.getItem('token')) {
    next({ name: 'Login' })
  } else {
    next()
  }
}

export const guardGuest = (to, from, next) => {
  if (localStorage.getItem('token')) {
    next({ name: 'Home' })
  } else {
    next()
  }
}

export const guardPermission = (to, from, next) => {
  const permission = store.getters.permissions.find(item => item.name === to.meta.permission)
  if (!permission || !permission.active) {
    next({ name: 'Home' })
  } else {
    next()
  }
}

export const guardRole = (to, from, next) => {
  const role = to.meta.roles.includes(store.getters.role)
  if (!role) {
    next({ name: 'Home' })
  } else {
    next()
  }
}

export const guardRoleAndPermission = (to, from, next) => {
  const role = to.meta.roles.includes(store.getters.role)
  if (!role) {
    next({ name: 'Home' })
  } else {
    const permission = store.getters.permissions.find(item => item.name === to.meta.permission)
    if (!permission || !permission.active) {
      next({ name: 'Home' })
    } else {
      next()
    }
  }
}

export const beforeEach = async (to, from, next) => {
  if (store.getters.loading) {
    await store.dispatch('initApp')
    next()
  } else {
    next()
  }
}

export const afterEach = to => {
  document.title = 'Spedimo - ' + i18n.t(`documentTitle.${to.name.toLowerCase()}`) || ''

  const icon = useFavicon()
  icon.value = 'static/img/favicon.png'
}
