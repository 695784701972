import * as signalR from '@microsoft/signalr'
import store from '@/store'
import * as types from '@/store/modules/users/users-mutation-types'
import { log } from '@/utils/logger'

const lang = localStorage.getItem('lang') || 'pl-PL'

const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${process.env.VUE_APP_BASE}stateHub?Language=${lang}`, {
    accessTokenFactory: () => localStorage.getItem('token')
  })
  .withAutomaticReconnect()
  .build()

export const usersStart = async () => {
  try {
    await connection.start()
    const users = await connection.invoke('GetUsers')
    log('USERHUB - GetUsers', users)
    store.commit(`users/${types.SET_USERS}`, users)
  } catch (e) {
    console.error(e.toString())
  }
}

export const usersStop = async () => {
  try {
    await connection.stop()
  } catch (e) {
    console.error(e.toString())
  }
}

connection.on('UserConnected', (userId) => {
  log('USERHUB - UserConnected', userId)
  store.commit(`users/${types.USER_CONNECTION}`, userId)
})

connection.on('UserDisconnected', (userId) => {
  log('USERHUB - UserDisconnected', userId)
  store.commit(`users/${types.USER_DISCONNECTION}`, userId)
})

connection.on('UserUpdatedOrAdded', (user) => {
  log('USERHUB - UserUpdatedOrAdded', user)
  store.commit(`users/${types.USER_UPDATE_OR_ADD}`, user)
})

connection.on('UserStateChange', (userId, onlineStatus) => {
  log('USERHUB - UserStateChange', userId, onlineStatus)
  store.commit(`users/${types.USER_STATE_CHANGE}`, { userId, onlineStatus })
})
