<template>
  <v-list class="stack-wrapper rounded py-0" outlined>
    <StackItem
      v-for="unpinedConversation in unpinedConversations"
      :key="`${unpinedConversation.id} - ${generateString()}`"
      :conversation="unpinedConversation"
      class="w-100"
    />
  </v-list>
</template>

<script>
import StackItem from '@/chat/Stack/StackItem'
import { generateString } from '@/utils/helpers'

export default {
  name: 'Stack',
  components: {
    StackItem
  },
  props: {
    unpinedConversations: Array
  },
  methods: {
    generateString
  }
}
</script>

<style scoped lang="scss">
.stack-wrapper {
  position: fixed;
  bottom: 14px;
  width: 190px;
  height: auto;
  max-height: 440px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border: solid grey 1px;
  background: white;
  z-index: 999;
  margin-left: 185px;
}
</style>
