<template>
  <div>
    <v-expand-transition>
      <Deal v-if="dealOpened && otherUser" :conversationId="conversationId" :currentThreadId="currentThreadId" :otherUserId="otherUser.id" />
      <RateHelpdesk v-if="showHelpdeskChatRate && !isCustomerServiceOrAdmin" :currentThreadId="currentThreadId" />
    </v-expand-transition>
    <div class="d-flex py-2 px-4 align-center justify-end shadow-top">
      <v-btn v-if="threadType !== conversationThreadTypes.Others && threadType !== conversationThreadTypes.OthersHelpdesk" color="primary" icon tile outlined @click="dealOpened = !dealOpened" class="mr-3 rounded">
        <v-icon v-if="dealAccepted" size="28">$rating</v-icon>
        <v-icon v-else size="32">$deal</v-icon>
      </v-btn>
      <v-btn v-else-if="isSupportThread && isCustomerServiceOrAdmin" icon class="white--text primary mr-3" :disabled="showHelpdeskChatRate" @click="closeThreadClick">
        <span>{{ $t('chatWindow.closeButton') }}</span>
      </v-btn>
      <div style="min-width: 220px; width: 100%">
        <v-textarea
          class="pl-1"
          dense
          v-model="text"
          ref="textarea"
          outlined
          single-line
          rows="1"
          no-resize
          hide-details
          :disabled="showHelpdeskChatRate"
          @keydown.enter.exact.prevent="sendMessage"
          @keydown.enter.shift.exact.prevent="text += '\n'"
        ></v-textarea>
      </div>
      <v-btn :disabled="blockedSendMessage || showHelpdeskChatRate" @click="sendMessage" icon tile outlined color="primary" class="rounded ml-3">
        <v-icon size="26">$send</v-icon>
      </v-btn>
    </div>
    <div class="d-flex py-2 px-4 align-end justify-end">
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
        <div>
          <v-icon v-on="on" v-bind="attrs">uil uil-ellipsis-h</v-icon>
        </div>
        </template>
        <v-list class="py-0">
          <v-list-item dense @click="openMultimedia">
            {{ $t('chatWindow.multimedia') }}
          </v-list-item>
          <v-list-item dense v-if="userIsWorker && !isSupportThread" @click="sendSms">
            {{ $t('chatWindow.sendSms') }}
          </v-list-item>
          <v-list-item dense @click="goToArchive">
            {{ $t('inputArea.goToArchive') }}
          </v-list-item>
          <v-list-item dense v-if="$store.getters.isAdmin || $store.getters.isCustomerService" @click="goToAddIssue">
            {{ $t('inputArea.addIssue') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon height="22" width="22" outlined tile class="rounded ml-1" :disabled="blockedSendMessage || showHelpdeskChatRate" v-on="on" v-bind="attrs">
            <v-icon small>$localization</v-icon>
          </v-btn>
        </template>
        <v-card width="155">
          <div class="body-2 px-2 py-3 text-center">
            {{ $t('chatWindow.sendLocationConfirm') }}
          </div>
          <v-divider class="mx-2"/>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" @click="sendLocation" depressed>
              {{ $t('common.yes') }}
            </v-btn>
            <v-btn color="primary" outlined depressed>
              {{ $t('common.no') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon height="22" width="22" :disabled="blockedSendMessage || showHelpdeskChatRate" v-on="on" v-bind="attrs" class="mx-2" outlined color="lightText">
            <v-icon size="10">$emoji</v-icon>
          </v-btn>
        </template>
        <v-emoji-picker @select="selectEmoji" :i18n="i18n"></v-emoji-picker>
      </v-menu>
      <v-btn icon height="22" width="22" outlined tile class="rounded" :disabled="blockedSendMessage || showHelpdeskChatRate" @click="sendFile">
        <v-icon small>$paperclip</v-icon>
      </v-btn>
    </div>
    <SendSmsDialog ref="sendSmsDialog" />
    <SendFileDialog ref="sendFileDialog" :conversationId="conversationId" :threadId="currentThreadId" />
    <MultimediaDialog ref="multimediaDialog" v-if="currentThreadId" :threadId="currentThreadId" />
  </div>
</template>

<script>
import { sendMessage, sendGeolocation } from '@/signalr/chat'
import SendSmsDialog from '../../components/dialogs/SendSmsDialog.vue'
import SendFileDialog from './Dialogs/SendFileDialog.vue'
import MultimediaDialog from './Dialogs/MultimediaDialog.vue'
import * as conversationThreadTypes from '@/types/conversation-thread-type'
import * as types from '@/types/message-types'

import { VEmojiPicker } from 'v-emoji-picker'
import Deal from './Deal.vue'
import RateHelpdesk from './RateHelpdesk'
import Service from '@/services'
export default {
  components: {
    SendSmsDialog,
    SendFileDialog,
    MultimediaDialog,
    VEmojiPicker,
    Deal,
    RateHelpdesk
  },
  name: 'InputArea',
  props: {
    conversationId: String,
    currentThreadId: {
      type: String,
      default: () => null
    },
    users: Array,
    isUnpined: Boolean,
    isSupportThread: Boolean,
    threadType: Number,
    blockedSendMessage: Boolean
  },
  data () {
    return {
      text: '',
      dealOpened: false,
      conversationThreadTypes
    }
  },
  computed: {
    currentThread () {
      const window = this.$store.getters['chat/windows'].find(
        (window) => window.id === this.conversationId
      )
      if (!window) {
        return null
      }

      const thread = window.conversation.threads.find(
        (thread) => thread.id === this.currentThreadId
      )
      return thread
    },
    showHelpdeskChatRate () {
      return this.currentThread?.showHelpdeskChatRate
    },
    dealAccepted () {
      return this.currentThread?.messages.find((el) => el.type === types.DEAL)?.offer
    },
    offerOutdated () {
      return !!this.currentThread?.messages.find((el) => el.type === types.OFFER_OUTDATED)
    },
    hasOfferOrCounterOffer () {
      return !!this.currentThread?.messages.find((el) => el.type === types.OFFER || el.type === types.COUNTEROFFER)
    },
    placeholder () {
      if (!this.currentThreadId) {
        return ''
      }
      if (!this.currentThread) {
        return ''
      }

      if (!this.currentThread.associatedItem) {
        return ''
      }

      if (this.currentThread.messages.length > 0) {
        return ''
      }

      return this.currentUser.chatTeaserActive ? this.currentUser.chatTeaser : ''
    },
    i18n () {
      if (!this.$i18n || !this.$i18n?.messages || !this.$i18n.locale) {
        return {}
      }
      return this.$i18n.messages[this.$i18n.locale]?.emojiPicker ?? {}
    },
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    currentUser () {
      return this.$store.getters.userCommonData
    },
    usersWithoutCurrent () {
      return this.users.filter((el) => el.user.id !== this.currentUserId)
    },
    otherUser () {
      return this.usersWithoutCurrent[0].user
    },
    userIsWorker () {
      return this.$store.getters.isOwnerOrWorker
    },
    isCustomerServiceOrAdmin () {
      return this.$store.getters.isCustomerService || this.$store.getters.isAdmin
    }
  },
  watch: {
    offerOutdated (newValue) {
      if (newValue) {
        this.dealOpened = false
      }
    },
    hasOfferOrCounterOffer: {
      deep: true,
      handler () {
        if (!this.hasOfferOrCounterOffer || this.offerOutdated) {
          return
        }
        this.dealOpened = true
      }
    }
  },
  methods: {
    selectEmoji (emoji) {
      this.text += emoji.data
    },
    goToArchive () {
      const path = '/archive/' + this.otherUser.name
      if (this.isUnpined) {
        const event = new CustomEvent('goToPath', { detail: path })
        window.dispatchEvent(event)
      } else {
        this.$router.push({ path })
      }
    },
    goToAddIssue () {
      if (this.$route.name === 'AddIssueCustomerService') {
        return
      }
      this.$router.push({ name: 'AddIssueCustomerService' })
    },
    goToRodo () {
      window.open('https://spedimo.eu/zgody_marketingowe/', '_blank').focus()
    },
    goToPrivacyPolicy () {
      window.open('https://spedimo.eu/polityka_prywatnosci/', '_blank').focus()
    },
    focus () {
      this.$refs.textarea.$refs.input.focus()
    },
    sendMessage () {
      if (this.blockedSendMessage) {
        return
      }
      if (this.text.toString().trim()) {
        sendMessage(this.conversationId, this.currentThreadId, this.text)
      }
      this.text = ''
      this.focus()
    },
    sendSms () {
      this.$refs.sendSmsDialog.open(this.otherUser.phone)
    },
    sendFile () {
      this.$refs.sendFileDialog.open()
    },
    openMultimedia () {
      this.$refs.multimediaDialog.open()
    },
    sendLocation () {
      this.loading = true
      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          try {
            const posision = {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude
            }
            await sendGeolocation(this.currentThreadId, posision)
          } finally {
            this.loading = false
          }
        },
        (error) => {
          try {
            const message = this.$t('geolocationErrorCodes.' + error.code)
            this.$store.dispatch('setErrorNotification', message)
          } finally {
            this.loading = false
          }
        }
      )
    },
    async closeThreadClick () {
      try {
        await Service.post('/Helpdesk/Complete', { threadId: this.currentThreadId })
        this.$store.commit('chat/CLOSE_HELPDESK_THREAD', { conversationId: this.conversationId, threadId: this.currentThread.id })
      } catch (e) {}
    }
  },
  mounted () {
    this.focus()
    this.text = this.placeholder
  },
  created () {
    const messages = this.currentThread?.messages
    const hasOffer = messages.find((el) => el.type === types.OFFER)?.offer
    const hasCounteroffer = messages.find((el) => el.type === types.COUNTEROFFER)?.offer
    const hasDeal = messages.find((el) => el.type === types.DEAL)?.offer

    if (hasOffer || hasCounteroffer || hasDeal) {
      this.dealOpened = true
    }
  }
}
</script>
