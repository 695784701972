import Vue from 'vue'
import Axios from 'axios'
import router from '@/router'
import store from '@/store'
import { getTokenData } from '@/utils/helpers'
import moment from 'moment'

Axios.defaults.baseURL = process.env.VUE_APP_BASE + 'api/'

Axios.interceptors.request.use(async config => {
  if (localStorage.getItem('token')) {
    const now = moment()
    const token = moment.unix(getTokenData().exp)
    if (moment(token).isBefore(now)) {
      return new Promise(() => {
        store.dispatch('clearSession')
        router.push({
          name: 'Login'
        })
      })
    }
    config.headers.common.Authorization =
      'Bearer ' + localStorage.getItem('token')
  }
  config.headers = {
    ...config.headers,
    Language: localStorage.getItem('lang') || navigator.language,
    Accept: 'application/json'
  }
  return config
})

Axios.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error.response) {
      if (error.response.status === 401) {
        await store.dispatch('clearSession')
        await router.push({
          name: 'Login'
        })
      }
      if (error.response.status === 404) {
        await router.push({
          name: 'Error404'
        })
      }
      if (error.response.status === 429) {
        await router.push({
          name: 'Error429'
        })
      }
      if (error.response.status === 500) {
        await router.push({
          name: 'Error500',
          params: { traceId: error.response.data.TraceId }
        })
      }
    }
    return Promise.reject(error)
  }
)

Vue.$http = Axios

Object.defineProperty(Vue.prototype, '$http', {
  get () {
    return Axios
  }
})

export default {
  async get (endpoint, params = {}, config = { notification: true }) {
    try {
      const response = await Vue.$http.get(`${endpoint}`, {
        params: { ...params }
      })
      return response
    } catch (e) {
      if (e.response.data.ShouldBeShown) {
        if (config.notification) {
          await store.dispatch('setErrorNotification', e.response.data.Title)
        } else {
          await store.dispatch('setErrorNotificationDialog', e.response.data.Title)
        }
      }
      throw e
    }
  },
  async getList (endpoint, page, pageSize, config = { notification: true }) {
    try {
      const response = await Vue.$http.get(`${endpoint}`, {
        params: {
          page: page,
          pageSize: pageSize
        }
      })
      return response
    } catch (e) {
      if (e.response.data.ShouldBeShown) {
        if (config.notification) {
          await store.dispatch('setErrorNotification', e.response.data.Title)
        } else {
          await store.dispatch('setErrorNotificationDialog', e.response.data.Title)
        }
      }
      throw e
    }
  },
  async getById (endpoint, id, config = { notification: true }) {
    try {
      const response = await Vue.$http.get(`${endpoint}/${id}`)
      return response
    } catch (e) {
      if (e.response.data.ShouldBeShown) {
        if (config.notification) {
          await store.dispatch('setErrorNotification', e.response.data.Title)
        } else {
          await store.dispatch('setErrorNotificationDialog', e.response.data.Title)
        }
      }
    }
  },
  async put (endpoint, data, config = { notification: true }) {
    try {
      const response = await Vue.$http.put(`${endpoint}`, data)
      return response
    } catch (e) {
      if (e.response.data.ShouldBeShown) {
        if (config.notification) {
          await store.dispatch('setErrorNotification', e.response.data.Title)
        } else {
          await store.dispatch('setErrorNotificationDialog', e.response.data.Title)
        }
      }
      throw e
    }
  },
  // put (endpoint, id, data) {
  //   return Vue.$http.put(`${endpoint}/${id}`, data)
  // },
  async post (endpoint, data, config = { notification: true }, progress) {
    try {
      const response = await Vue.$http.post(`${endpoint}`, data, {
        onUploadProgress (e) {
          if (progress !== undefined) {
            progress(e)
          }
        }
      }
      )
      return response
    } catch (e) {
      if (e.response.data.ShouldBeShown) {
        if (config.notification) {
          await store.dispatch('setErrorNotification', e.response.data.Title)
        } else {
          await store.dispatch('setErrorNotificationDialog', e.response.data.Title)
        }
      }
      throw e
    }
  },
  async delete (endpoint, data, config = { notification: true }) {
    try {
      const response = await Vue.$http.delete(`${endpoint}`, { data: { ...data } })
      return response
    } catch (e) {
      if (e.response.data.ShouldBeShown) {
        if (config.notification) {
          await store.dispatch('setErrorNotification', e.response.data.Title)
        } else {
          await store.dispatch('setErrorNotificationDialog', e.response.data.Title)
        }
      }
      throw e
    }
  },
  async getFile (endpoint, params = {}, config = { notification: true }) {
    try {
      const response = await Vue.$http.get(`${endpoint}`, {
        params: { ...params },
        responseType: 'blob'
      })
      return response
    } catch (e) {
      if (e.response.data.ShouldBeShown) {
        if (config.notification) {
          await store.dispatch('setErrorNotification', e.response.data.Title)
        } else {
          await store.dispatch('setErrorNotificationDialog', e.response.data.Title)
        }
      }
      throw e
    }
  },
  async postFile (endpoint, type, id, data, config = { notification: true }) {
    try {
      const response = await Vue.$http.post(`${endpoint}/${type}/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    } catch (e) {
      if (e.response.data.ShouldBeShown) {
        if (config.notification) {
          await store.dispatch('setErrorNotification', e.response.data.Title)
        } else {
          await store.dispatch('setErrorNotificationDialog', e.response.data.Title)
        }
      }
      throw e
    }
  }
}
