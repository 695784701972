var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between pa-4",staticStyle:{"background-color":"#006446"}},[_c('div',{staticStyle:{"width":"140px"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"$magnify","background-color":"white","placeholder":_vm.$t('common.search'),"hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.$store.getters.isMarketUser)?_c('div',{staticClass:"white--text font-weight-bold pointer text-center",on:{"click":function($event){return _vm.$emit('change', _vm.page.HELP)}}},[_vm._v(_vm._s(_vm.$t("chatSidebar.help")))]):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"border":"solid 1px white","border-radius":"5px"}},'div',attrs,false),on),[_c('v-icon',{staticClass:"pa-1",attrs:{"color":"white"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('ChatMenu')],1)],1),_c('div',{staticClass:"d-flex pa-2 justify-space-between"},[(_vm.$store.getters.isMarketUser)?[(_vm.$store.getters.isMarketUser)?_c('div',{staticClass:"pa-2 pointer",on:{"click":_vm.showContacts}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover || _vm.tab === _vm.page.CONTACTS ? 'primary' : 'separator',"size":"24"}},[_vm._v("$contacts")])]}}],null,false,2545773080)})],1):_vm._e(),(_vm.$store.getters.isMarketUser)?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"pa-2 pointer",on:{"click":function($event){return _vm.$emit('change', _vm.page.MARKET)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover || _vm.tab === _vm.page.MARKET ? 'primary' : 'separator',"size":"24"}},[_vm._v("$vehicles")])]}}],null,false,3916128951)})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"pa-2 pointer",on:{"click":function($event){return _vm.$emit('change', _vm.page.FREIGHT)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover || _vm.tab === _vm.page.FREIGHT ? 'primary' : 'separator',"size":"24"}},[_vm._v("$freights")])]}}],null,false,4031059979)})],1),_c('v-divider',{attrs:{"vertical":""}})]:_vm._e(),_c('div',{staticClass:"pa-2 pointer",on:{"click":function($event){return _vm.$emit('change', _vm.page.OTHER)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover || _vm.tab === _vm.page.OTHER ? 'primary' : 'separator',"size":"24"}},[_vm._v("$others")])]}}])})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"pa-2 pointer",on:{"click":function($event){return _vm.$emit('change', _vm.page.LAST)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover || _vm.tab === _vm.page.LAST ? 'primary' : 'separator',"size":"24"}},[_vm._v("$last")])]}}])})],1)],2),_c('v-divider',{staticClass:"mx-4"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }