<template>
  <v-menu offset-y left top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon tile class="rounded mr-1 ml-2" :class="buttonClass" x-small v-bind="attrs" v-on="on">
        <v-icon color="white">uil uil-ellipsis-h</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">
      <v-list-item v-for="conversation in conversations" :key="conversation.chatWindowId" :class="listItemClass(conversation)" @click="openConversation(conversation)">
        <v-list-item-title>
          <div class="d-flex align-center">
            <div v-if="conversation.conversation.type === 1" class="primary rounded" style="padding: 2px">
              <v-icon size="18" color="white">$bot</v-icon>
            </div>
            <StatusAvatar v-else-if="otherUser(conversation)" :userId="otherUser(conversation).id" avatarColor="primary" textColor="white" :size="24" showStatus />
            <div class="ml-2">{{ getConversationName(conversation) | trimText(20) }}</div>
          </div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { NEW_DEAL_MESSAGE, NEW_MESSAGE } from '@/types/new-message-type'
import StatusAvatar from '../components/StatusAvatar.vue'

export default {
  components: { StatusAvatar },
  name: 'MoreChatWindows',
  props: {
    conversations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    users () {
      return this.$store.getters['users/users']
    },
    hasNewMessage () {
      return this.conversations
        .filter(conversation => conversation.hasNewMessage && conversation.newMessageType === NEW_MESSAGE)
        .length > 0
    },
    newMessageType () {
      return this.conversations
        .filter(conversation => conversation.hasNewMessage && conversation.newMessageType === NEW_DEAL_MESSAGE)
        .length > 0
    },
    buttonClass () {
      return {
        primary: !this.hasNewMessage && !this.newMessageType,
        'new-message': this.hasNewMessage && !this.newMessageType,
        'new-deal-message': this.newMessageType
      }
    }
  },
  methods: {
    getConversationName (conversation) {
      if (conversation?.conversation?.type === 1) {
        return this.$t('chatWindow.supportSpedimo')
      }
      // if (conversation && conversation.conversation && conversation.conversation.users) {
      //   const otherUser = conversation.conversation.users.find((el) => el.user.id !== this.currentUserId)?.user

      //   if (otherUser) {
      //     return this.getUserById(otherUser.id).fullName
      //   } else return ''
      // }
      const otherUser = this.otherUser(conversation)
      if (otherUser) return this.getUserById(otherUser.id).fullName
      return ''
    },
    otherUser (conversation) {
      if (conversation && conversation.conversation && conversation.conversation.users) return conversation.conversation.users.find((el) => el.user.id !== this.currentUserId)?.user
      return null
    },
    getUserById (id) {
      return this.users.find((el) => el.id === id)
    },
    openConversation (conversation) {
      this.$store.dispatch('chat/openConversation', { conversationId: conversation.id, threadId: conversation.currentThreadId })
    },
    listItemClass (conversation) {
      return {
        'new-message': conversation.hasNewMessage && conversation.newMessageType === NEW_MESSAGE,
        'new-deal-message': conversation.hasNewMessage && conversation.newMessageType === NEW_DEAL_MESSAGE
      }
    }
  }
}
</script>

<style scoped></style>
