<template>
  <v-app-bar color="white" height="60" clipped-left clipped-right fixed app>
    <v-app-bar-nav-icon v-if="isMobile" @click="toggleMenuDrawer" />
    <v-toolbar-title class="pl-6">
      <v-img contain :src="require('@/assets/logo/SPEDIMO_logo.svg')" height="34" width="220" class="cursor-pointer" @click="$router.push({ path: '/' }).catch(err => {})" />
    </v-toolbar-title>
    <v-spacer />
    <v-menu v-if="!isDriver" offset-y max-height="300" max-width="320">
      <template v-slot:activator="{ on, attrs }">
        <div class="mx-2">
          <v-hover v-slot="{ hover }">
            <v-icon v-if="countNewNotification > 0" color="red" size="26" v-bind="attrs" v-on="on">$notifications</v-icon>
            <v-icon v-else :color="hover ? 'primary' : 'separator'" size="26" v-bind="attrs" v-on="on">$notifications</v-icon>
          </v-hover>
        </div>
      </template>
      <v-list v-if="notifications && notifications.length > 0" class="py-0">
        <template v-for="(notification, index) in notifications">
          <v-list-item :key="`notification-${index}`" link :class="{ 'primary lighten-5': !notification.isRead }" @click="notificationClick(notification)">
            <v-list-item-content>
              <span>{{ notification.description }}</span>
            </v-list-item-content>
            <v-list-item-action class="justify-start">
              <v-list-item-action-text>
                {{ notification.creationDateTime | dateFormat("DD.MM.YYYY") }}
              </v-list-item-action-text>
              <v-list-item-action-text>
                {{ notification.creationDateTime | dateFormat("HH:mm") }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="notifications[index + 1]" :key="`divider-${index}`" />
        </template>
      </v-list>
      <v-list v-else class="py-0">
        <v-list-item>
          <v-list-item-content>
            <span>{{ $t("notifications.noData") }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-divider v-if="!isDriver" vertical class="mx-2"></v-divider>
    <div v-if="!isDriver" class="mx-2">
      <v-hover v-slot="{ hover }">
        <v-icon v-if="chatNotifications > 0" :color="hover ? 'primary' : 'separator'" size="26" @click="toggleChatDrawer">$chatMsg</v-icon>
        <v-icon v-else :color="hover ? 'primary' : 'separator'" size="26" @click="toggleChatDrawer">$chat</v-icon>
      </v-hover>
    </div>
    <v-divider v-if="!isDriver" vertical class="mx-2"></v-divider>
    <div v-if="isUserImpersonalized" class="d-flex align-center">
      <v-btn color="primary" outlined @click="backToBaseAccount">{{ $t('appBar.disconnect') }}</v-btn>
      <div class="d-flex flex-column ml-4">
        <StatusAvatar :userId="userCommonData.id" :size="20" class="text-center" showStatus></StatusAvatar>
        <span color="primary" class="text-caption primary--text">
          {{ impersonalizedUserNameAndSurname }}
        </span>
      </div>
    </div>
    <v-menu v-else bottom left offset-y min-width="120">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <StatusAvatar :userId="userCommonData.id" :size="30" class="ml-2" showStatus></StatusAvatar>
        </div>
      </template>
      <v-list class="py-0">
        <template v-for="(item, index) in userMenu">
          <v-divider v-if="item.divider" :key="index" />
          <v-list-item
            v-else-if="item.action"
            :key="index"
            @click="item.command"
          >
            <v-list-item-title class="text-center">{{ item.label }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-else :key="index" :to="item.to">
            <v-list-item-title class="text-center">{{ item.label }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapGetters } from 'vuex'
import Service from '@/services'
import StatusAvatar from '@/components/StatusAvatar.vue'
import { usersStart, usersStop } from '@/signalr/users'
import { downloadFile } from '@/utils/helpers'
import { notificationsStart, notificationsStop, markAsRead } from '@/signalr/notifications'
import { start, stop } from '@/signalr/chat.js'
import {
  CHAT_EXPORTED,
  DOCUMENT_UPDATE,
  HELPDESK,
  LOGS_EXPORTED,
  NEW_OFFER,
  REPORT_EXPORTED
} from '@/types/notification-types'

export default {
  components: { StatusAvatar },
  name: 'AppBar',
  data () {
    return {
      isMobile: false,
      userMenu: [
        {
          label: this.$t('userMenu.myProfile'),
          to: '/settings/profile'
        },
        {
          divider: true
        },
        {
          label: this.$t('userMenu.logout'),
          action: true,
          command: this.logout
        }
      ]
    }
  },
  beforeDestroy () {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  computed: {
    ...mapGetters({
      isDriver: 'isDriver',
      impersonalizedUserNameAndSurname: 'getFullName',
      userCommonData: 'userCommonData',
      notifications: 'notification/notifications',
      countNewNotification: 'notification/countNewNotification',
      chatNotifications: 'chat/notifications'
    }),
    isUserImpersonalized () {
      return this.$store.state.userCommonData.allowBackToAdminPanel
    }
  },
  mounted () {
    this.onResize()

    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 1500
    },
    toggleMenuDrawer () {
      this.$emit('burger-menu-drawer-change')
    },
    toggleChatDrawer () {
      this.$emit('chat-drawer-change')
    },
    logout () {
      this.$store.dispatch('logout')
    },
    async backToBaseAccount () {
      usersStop()
      notificationsStop()
      stop()
      const { data } = await Service.post('/Impersonation/BackToBaseAccount')
      localStorage.setItem('token', data.token)
      await this.$store.dispatch('fetchUserData')
      this.$store.dispatch('chat/clearConversations')
      start()
      usersStart()
      notificationsStart()
      this.$router.push({ name: 'ImpersonationCustomerService' })
    },
    async notificationClick (notification) {
      await markAsRead(notification.id)
      this.goToView(notification)
    },
    async goToView (notification) {
      if (notification.type === NEW_OFFER) {
      } else if (notification.type === DOCUMENT_UPDATE) {
      } else if (notification.type === CHAT_EXPORTED || notification.type === REPORT_EXPORTED || notification.type === LOGS_EXPORTED) {
        await this.getExportedFile(notification.referenceObjectId)
      } else if (notification.type === HELPDESK) {
        await this.$store.dispatch('helpdesk')
        await this.$router.push({ name: 'HelpDesk' })
      }
    },
    async getExportedFile (id) {
      try {
        const { data } = await Service.getFile(`/File/GetGeneratedFile?fileGuid=${id}`)
        let type = data.type.slice(-3)
        if (type === 'eet') {
          type = 'xlsx'
        }
        downloadFile({ name: `${id}.${type}` }, data)
      } catch (e) {}
    }
  }
}
</script>
