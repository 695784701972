<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.mobile" max-width="300" persistent>
    <v-card v-if="orderId || reviewId" :loading="loading" outlined>
      <v-form ref="form" v-model="valid">
      <v-card-title class="pt-2 pr-2 pl-4 d-flex align-start">
        <div>
          <h5 class="primary--text mt-2">{{ $t('reviews.clientTitle') }}</h5>
          <v-input :value="rate" :rules="requiredRules">
            <v-rating v-model="rate" :rules="requiredRules" length="5" color="accent" size="18" :readonly="readOnly" />
          </v-input>
        </div>
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon>$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-4">
        <label>{{ $t('reviews.comment') }}</label>
        <v-textarea v-model="comment" :rules="commentRules" outlined rows="2" auto-grow class="mb-2" :disabled="readOnly" />
        <v-row no-gutters>
          <v-col cols="6">
            <label>{{ $t('reviews.communicationRate') }}</label>
            <v-input :value="communicationRate" :rules="requiredRules">
              <v-rating v-model="communicationRate" :rules="requiredRules" length="5" color="accent" size="18" :readonly="readOnly" />
            </v-input>
          </v-col>
          <v-col cols="6">
            <label>{{ $t('reviews.paymentRate') }}</label>
            <v-input :value="paymentRate" :rules="requiredRules">
              <v-rating v-model="paymentRate" :rules="requiredRules" length="5" color="accent" size="18" :readonly="readOnly" />
            </v-input>
          </v-col>
          <v-col cols="6">
            <label>{{ $t('reviews.accordanceWithArrangementsRate') }}</label>
            <v-input :value="accordanceWithArrangementsRate" :rules="requiredRules">
              <v-rating v-model="accordanceWithArrangementsRate" :rules="requiredRules" length="5" color="accent" size="18" :readonly="readOnly" />
            </v-input>
          </v-col>
        </v-row>
      </v-card-text>
      </v-form>
    <v-card-actions v-if="!readOnly" class="pa-4 justify-center">
      <v-btn v-if="editBOK" color="primary" depressed :disabled="loading" :loading="loading" @click="saveRate">{{ $t('common.save') }}</v-btn>
      <v-btn v-else color="primary" depressed :disabled="loading" :loading="loading" @click="addRate">{{ $t('reviews.add') }}</v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>
<script>
import Service from '@/services'
export default {
  name: 'ReviewClient',
  components: {},
  props: {
    orderId: null,
    review: null
  },
  data () {
    return {
      loading: false,
      dialog: false,
      valid: true,
      readOnly: false,
      editBOK: false,
      reviewId: null,
      rate: 0,
      comment: null,
      communicationRate: 0,
      paymentRate: 0,
      accordanceWithArrangementsRate: 0,
      requiredRules: [
        (v) => (v && v > 0) || this.$t('validation.fieldRequired')
      ],
      commentRules: [
        v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
        v => (v && v.length <= 250) || this.$t('validation.maxCountChar', { count: 250 })
      ]
    }
  },
  async created () {},
  computed: {},
  methods: {
    show () {
      this.dialog = true
      if (this.review) {
        this.readOnly = true
        this.comment = this.review.comment
        this.rate = this.review.rate
        this.communicationRate = this.review.communicationRate
        this.paymentRate = this.review.paymentRate
        this.accordanceWithArrangementsRate = this.review.accordanceWithArrangementsRate
      }
    },
    edit (review) {
      if (review) {
        this.dialog = true
        this.editBOK = true
        this.reviewId = review.reviewId
        this.comment = review.comment
        this.rate = review.generalRate
        this.communicationRate = review.communicationRate
        this.paymentRate = review.paymentRate
        this.accordanceWithArrangementsRate = review.accordanceWithArrangementsRate
      }
    },
    closeDialog () {
      this.$emit('closed')
      this.$refs.form.reset()
      this.rate = 0
      this.communicationRate = 0
      this.paymentRate = 0
      this.accordanceWithArrangementsRate = 0
      this.dialog = false
      this.readOnly = false
    },
    async addRate () {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      this.loading = true
      try {
        await Service.post('Reviews/ReviewClient', {
          orderId: this.orderId,
          rate: this.rate,
          comment: this.comment,
          communicationRate: this.communicationRate,
          paymentRate: this.paymentRate,
          accordanceWithArrangementsRate: this.accordanceWithArrangementsRate
        })
        this.$emit('add')
        this.closeDialog()
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
      } finally {
        this.loading = false
      }
    },
    async saveRate () {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      this.loading = true
      try {
        await Service.put('Reviews/EditClientReview', {
          reviewId: this.reviewId,
          rate: this.rate,
          comment: this.comment,
          communicationRate: this.communicationRate,
          paymentRate: this.paymentRate,
          accordanceWithArrangementsRate: this.accordanceWithArrangementsRate
        })
        this.closeDialog()
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
</style>
