export const getDefaultState = () => {
  return {
    isLoggedIn: false,
    loading: true,
    enums: {},
    snackBar: {
      type: 'success',
      open: false,
      text: '',
      timeout: 10000
    },
    notificationDialog: {
      type: 'error',
      open: false,
      text: '',
      action: () => {}
    },
    userCommonData: {},
    languages: ['pl'],
    defaultLanguage: 'pl-PL',
    contacts: [],
    currencies: [],
    countries: [],
    helpConversations: [],
    titleNotificationInterval: null
  }
}

export default getDefaultState()
