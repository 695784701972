<template>
  <div class="d-flex">
    <div class="d-flex align-center mr-2 ml-1">
      <div class="primary rounded" style="padding: 2px">
        <v-icon size="18" color="white">$bot</v-icon>
      </div>
    </div>
    <div class="pa-2 rounded text-wrap text-wrapper">
      <div v-for="(text, index) in splitedValue" :key="index" class="d-inline-block">
        {{ text }}
        <template v-if="value.helpdeskTopics && value.helpdeskTopics.length > 0">
          <div v-for="(helpdeskTopic, indexHelpdeskTopic) in value.helpdeskTopics" :key="`helpdeskTopic-${indexHelpdeskTopic}`" >
            {{ helpdeskTopic.text }}
          </div>
        </template>
      </div>
    </div>
    <div v-if="!isTranslated && !isCurrentUserSender" class="d-flex align-center" :class="isCurrentUserSender ? 'order-first ml-auto' : ''">
      <v-menu absolute offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon small v-on="on" v-bind="attrs">
            <v-icon color="separator">uil uil-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item dense @click="translateMessage">
            <v-icon small class="mr-2">$translate</v-icon>
            {{ $t('chatWindow.translateMessage') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import Services from '@/services'
export default {
  components: {},
  props: {
    value: Object,
    threadId: String,
    allowTranslate: Boolean,
    idToUseAsCurrentUser: String
  },
  data () {
    return {
      files: [],
      popupFile: null
    }
  },
  computed: {
    isTranslated () {
      return !!this.value.translation
    },
    isCurrentUserSender () {
      return this.currentUserId === this.value.sender
    },
    currentUserId () {
      if (this.idToUseAsCurrentUser) {
        return this.idToUseAsCurrentUser
      }
      return this.$store.getters.userCommonData.id
    },
    splitedValue () {
      const value = this.isTranslated ? this.value.translation : this.value.text
      return value.toString().split('\n')
    }
  },
  methods: {
    async translateMessage () {
      const response = await Services.get('ExternalApi/Translate', {
        text: this.value.text
      })
      this.$store.dispatch('chat/translateMessage', {
        id: this.value.id,
        text: response.data.data.translations[0].translatedText
      })
    }
  }
}
</script>

<style scoped>
.message {
  margin-left: auto;
  margin-right: auto;
}
</style>
