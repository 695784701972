<template>
<div>
  <v-divider />
  <v-row class="my-2" no-gutters>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.vehicleSize') }}:</h4>
      <p>{{ vehicleSizeType }}</p>
      <h4 class="primary--text">{{ $t('offerDetails.vehicleType') }}:</h4>
      <span v-for="(type, index) in details.type" :key="index">{{ type }} </span>
    </v-col>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.loadFormType') }}:</h4>
      <p>
        <span v-for="(loadFormType, index) in loadFormTypes" :key="index">
          {{ loadFormType }}
        </span>
      </p>
      <h4 class="primary--text">{{ $t('offerDetails.vehicleOptions') }}:</h4>
      <p>
        <span v-for="(option, index) in details.options" :key="index">
          {{ option }}
        </span>
      </p>
    </v-col>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.size') }}:</h4>
      <p class="ma-0">
        {{ $t('offerDetails.length') }}:
        <span v-if="details.length" class="lightText--text">{{ details.length }} m</span><br/>
        <span>{{ $t('offerDetails.width') }}: </span>
        <span v-if="details.width">{{ details.width }} m</span><br/>
        <span>{{ $t('offerDetails.height') }}: </span>
        <span v-if="details.height">{{ details.height }} m</span><br/>
        <span>{{ $t('offerDetails.volume') }}: </span>
        <span v-if="details.volume > 0">{{ details.volume }} m3</span>
      </p>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { getEnumDescription } from '@/utils/helpers'

export default {
  name: 'FreightVehicleRequirementsPanel',
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  computed: {
    vehicleLoadFormTypes () {
      return this.$store.getters.enums.vehicleLoadFormTypes
    },
    loadFormTypes () {
      return this.details.loadForms
        .map(t => (getEnumDescription(this.vehicleLoadFormTypes, t)))
    },
    vehicleSizeTypes () {
      return this.$store.getters.enums.vehicleSizeTypes
    },
    vehicleSizeType () {
      return getEnumDescription(this.vehicleSizeTypes, this.details.size)
    }
  }
}
</script>
