<template>
  <div style="width: 300px">
    <ChatHeader :tab="tab" @change="changePage"></ChatHeader>
    <ChatOffline v-if="!isOnline"></ChatOffline>
    <Component :is="displayPage" :search="search" />
    <div class="pt-4 shadow-top">
      <v-divider class="mx-4" />
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <div>
              <v-icon size="20" v-if="dockChat" @click="toggleDockChat" class="pointer" color="primary" style="transform: rotate(-180deg)">$arrowLeft</v-icon>
              <v-icon size="20" v-else @click="toggleDockChat" class="pointer" color="primary">$arrowLeft</v-icon>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>
<script>
import * as page from './types/chat-page.js'
import ChatHeader from './ChatHeader/ChatHeader.vue'
import ChatOffline from './ChatHeader/ChatOffline.vue'
export default {
  name: 'Chat',
  components: {
    ChatHeader,
    ChatOffline
  },
  data () {
    return {
      isOnline: true,
      tab: page.LAST,
      search: null,
      pages: [
        {
          id: page.CONTACTS,
          component: () => import('./ChatTabs/ContactList/Contacts.vue')
        },
        {
          id: page.HELP,
          component: () => import('./ChatTabs/24TSL/24TSL.vue')
        },
        {
          id: page.MARKET,
          component: () => import('./ChatTabs/Market/Market.vue')
        },
        {
          id: page.FREIGHT,
          component: () => import('./ChatTabs/Freight/Freight.vue')
        },
        {
          id: page.OTHER,
          component: () => import('./ChatTabs/Other/Other.vue')
        },
        {
          id: page.LAST,
          component: () => import('./ChatTabs/LastMsg/LastMessages.vue')
        },
        {
          id: page.SEARCH,
          component: () => import('./ChatTabs/Search/Search.vue')
        }
      ]
    }
  },
  computed: {
    displayPage () {
      const component = this.pages.find((item) => item.id === this.tab)
      if (!component) {
        return this.pages[0]
      }

      return component.component
    },
    dockChat () {
      return this.$store.getters.userCommonData.dockChat
    }
  },
  methods: {
    changePage (id, val) {
      this.tab = id
      this.search = val
    },
    async toggleDockChat () {
      this.$emit('hide')
    }
  }
}
</script>
<style>
div#dockChat .chatWrapp {
  height: calc(100vh - 295px);
  overflow-y: auto;
}
div#dockChat .offersWrapp {
  height: calc(100vh - 339px);
  overflow-y: auto;
}
.chatWrapp {
  height: calc(100vh - 235px);
  overflow-y: auto;
}
.offersWrapp {
  height: calc(100vh - 279px);
  overflow-y: auto;
}

</style>
