<template>
  <div>
    <AppBar
      @burger-menu-drawer-change="burgerMenuDrawerChange"
      @chat-drawer-change="chatDrawerChange"
    />
    <Sidebar
      :burgerMenuDrawer="burgerMenuDrawer"
      @burger-menu-drawer-change="burgerMenuDrawerChange"
      @drawer-change="chatDrawerChange"
    />
    <v-main>
      <v-container class="px-0 pt-14" fluid style="padding-bottom: 135px">
        <router-view :key="$route.path" />
      </v-container>
      <Notification />
      <NotificationDialog />
    </v-main>
    <ChatWrapper v-if="!isUnpined" />
    <Stack
      v-if="displayStack"
      :style="styles"
      :unpinedConversations="unpinedWindows"
    />
    <ChatDrawer :drawer="chatDrawer" @chat-drawer-change="chatDrawerChange" />
    <v-tour v-if="enableTour" name="myTour" :steps="steps">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>
<script>
import Notification from '@/components/Notification'
import NotificationDialog from '@/components/NotificationDialog'
import Sidebar from './components/Sidebar.vue'
import AppBar from './components/AppBar.vue'
import { usersStart, usersStop } from '@/signalr/users'
import { notificationsStart, notificationsStop } from '@/signalr/notifications'
import ChatWrapper from '@/chat/ChatWrapper'
import ChatDrawer from '@/components/chat/ChatDrawer'
import { registerWebPushServiceWorker, unregisterWebPushServiceWorker } from '@/services/WebPush/WebPushService.js'
import Stack from '@/chat/Stack/Stack'
import { start, stop } from '@/signalr/chat'
import Messenger from '@/utils/messenger.js'

export default {
  name: 'Layout',
  components: {
    Notification,
    NotificationDialog,
    Sidebar,
    AppBar,
    ChatWrapper,
    ChatDrawer,
    Stack
  },
  data () {
    return {
      enableTour: false,
      steps: [
        {
          target: '#nav_menu',
          header: {
            title: 'Tytuł'
          },
          content: '<strong>Przykladowy opis 1</strong><br> Poczatek samouczka.'
        },
        {
          target: '#app > div.v-application--wrap > div:nth-child(1) > header > div > div:nth-child(4) > button',
          header: {
            title: 'Powiadomienia'
          },
          content: 'Tu znajdziesz <strong>Powiadomienia</strong>'
        },
        {
          target: '#app > div > div:nth-child(1) > header > div > div:nth-child(6) > button > svg',
          header: {
            title: 'Wiadomości'
          },
          content: 'Tutaj znajdziesz <strong>wiadomości</strong><br> Koniec samouczka.'
        }
      ],
      burgerMenuDrawer: true
    }
  },
  beforeCreate () {
    usersStart()
    notificationsStart()
    start()
    registerWebPushServiceWorker()
  },
  mounted () {
    window.addEventListener('beforeunload', this.clearUnPinedChatWindows)
    Messenger.initParent(window)
    this.exampleTour()
  },
  beforeDestroy () {
    usersStop()
    notificationsStop()
    stop()
    unregisterWebPushServiceWorker()
    window.removeEventListener('beforeunload', this.clearUnPinedChatWindows)
  },
  created () {
    this.enableTour = process.env.NODE_ENV !== 'production'
    const recaptcha = this.$recaptchaInstance
    if (recaptcha) { recaptcha.hideBadge() }
  },
  computed: {
    unpinedWindows () {
      return this.$store.getters['chat/popupWindows']
    },
    styles () {
      const position = this.$store.getters['chat/positionStack']
      const result = {}

      if (position.right !== null && position.right >= 0) {
        result.right = `${position.right}px`
      }

      if (position.left !== null && position.left >= 0) {
        result.left = `${position.left}px`
      }

      return result
    },
    displayStack () {
      return this.unpinedWindows.length > 0
    },
    isUnpined () {
      return this.$route.path.includes('unpined-chat')
    },
    chatDrawer: {
      get () {
        return this.$store.getters['chat/drawer']
      },
      set (value) {
        this.$store.commit('chat/SET_DRAWER', value)
      }
    }
  },
  methods: {
    burgerMenuDrawerChange () {
      this.burgerMenuDrawer = !this.burgerMenuDrawer
    },
    exampleTour () {
      this.$tours.myTour.start()
    },
    chatDrawerChange (value) {
      this.chatDrawer = value ?? !this.chatDrawer
    },
    clearUnPinedChatWindows () {
      this.$store.dispatch('chat/clearConversations')
    }
  }
}
</script>
