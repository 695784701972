<template>
  <div v-if="user">
    <v-badge :value="showStatus" bottom dot overlap :color="color" offset-x="9" offset-y="9">
      <v-avatar :color="avatarColor" :size="size" rounded dark outlined>
        <v-img v-if="user.avatar && user.avatar.length > 0" :src="getAvatar" />
        <span v-else class="white--text caption font-weight-bold text-uppercase">{{ initials }}</span>
      </v-avatar>
    </v-badge>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as colors from '@/types/status-colors.js'
import * as userStatus from '@/types/user-status.js'
import { fileUrl, getInitials } from '@/utils/helpers'

export default {
  name: 'StatusAvatar',
  props: {
    userId: String,
    showStatus: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 36
    },
    avatarColor: {
      type: String,
      default: 'primary'
    },
    textColor: {
      type: String,
      default: 'black'
    },
    border: {
      type: String,
      default: 'none'
    }
  },
  data () {
    return {}
  },
  computed: {
    initials () {
      return getInitials(this.user.name, this.user.surname)
    },
    getAvatar () {
      return fileUrl(this.user.avatar)
    },
    ...mapGetters({
      users: 'users/users'
    }),
    color () {
      switch (this.user.onlineStatus) {
        case userStatus.OFFLINE:
          return colors.OFFLINE
        case userStatus.BUSY:
          return colors.BUSY
        case userStatus.INVISIBLE:
          return colors.OFFLINE
        case userStatus.BERIGHTBACK:
          return colors.BERIGHTBACK
        case userStatus.ONLINE:
          return colors.ONLINE
        default:
          return colors.OFFLINE
      }
    },
    user () {
      return this.users.find((user) => user.id === this.userId)
    }
  },
  methods: {}
}
</script>
<style>
.v-badge--dot .v-badge__badge {
  height: 6px;
  width: 6px;
}
</style>
