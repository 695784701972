export const TEXT = 0
export const FILE = 1
export const DEAL = 2
export const CHATBOT = 3
export const OFFER = 4
export const COUNTEROFFER = 5
export const GEOLOCALISATION = 6
export const AUDIO = 7
export const HELPDESK_CHAT_COMPLETION = 8
export const OFFER_OUTDATED = 9
export const REVIEW_REMINDER = 10
