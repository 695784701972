<template>
  <div v-if="value" class="generic-message-wrapper" :class="{ 'pb-2': !isCurrentUserSender }" v-intersect.once="markAsRead">
  <v-row no-gutters>
    <v-col cols="12" v-if="value.showDate" class="text-center">
      <span class="body-2">{{ value.creationDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
    </v-col>
    <v-col cols="1" align-self="end">
      <StatusAvatar
        v-if="!isCurrentUserSender"
        :userId="value.sender"
        avatarColor="primary"
        textColor="white"
        class="mb-2 ml-1"
        :size="20" />
    </v-col>
    <v-col cols="10">
      <!-- TODO: wydzielenie file-message z text-message -->
      <text-message
        v-if="type === types.TEXT || type === types.HELPDESK_CHAT_COMPLETION || type === types.REVIEW_REMINDER || type === types.OFFER_OUTDATED"
        :value="value"
        :threadId="threadId"
        :isArchive="isArchive"
        :idToUseAsCurrentUser="idToUseAsCurrentUser"
      >
      </text-message>
      <file-message
        v-if="type === types.FILE"
        :value="value"
        :threadId="threadId"
        :isArchive="isArchive"
        :idToUseAsCurrentUser="idToUseAsCurrentUser"
      >
      </file-message>
      <chat-bot-message
        v-if="type === types.CHATBOT"
        :value="value"
        :threadId="threadId"
        :isArchive="isArchive"
        :idToUseAsCurrentUser="idToUseAsCurrentUser"
      >
      </chat-bot-message>
      <geolocation-message
        v-if="type === types.GEOLOCALISATION"
        :value="value"
        :threadId="threadId"
        :isArchive="isArchive"
        :idToUseAsCurrentUser="idToUseAsCurrentUser"
      >
      </geolocation-message>
      <offer-message
        v-if="type === types.OFFER || type === types.COUNTEROFFER"
        :value="value"
        :threadId="threadId"
        :isArchive="isArchive"
        :idToUseAsCurrentUser="idToUseAsCurrentUser"
      ></offer-message>
      <deal-message
        v-if="type === types.DEAL"
        :value="value"
        :threadId="threadId"
        :isArchive="isArchive"
        :idToUseAsCurrentUser="idToUseAsCurrentUser"
      ></deal-message>
      <audio-message
        v-if="type === types.AUDIO"
        :value="value"
        :threadId="threadId"
        :isArchive="isArchive"
        :idToUseAsCurrentUser="idToUseAsCurrentUser"
      />
      <div v-if="isCurrentUserSender" class="d-flex justify-end">
        <ReadReceivedMessageIcon :read="othreUserMessage.read" :received="othreUserMessage.received" />
      </div>
    </v-col>
  </v-row>
  </div>
</template>
<script>
import ChatBotMessage from './ChatBotMessage.vue'
import OfferMessage from './OfferMessage.vue'
import GeolocationMessage from './GeolocationMessage.vue'
import TextMessage from './TextMessage.vue'
import AudioMessage from './AudioMessage.vue'
import DealMessage from './DealMessage.vue'
import FileMessage from './FileMessage.vue'
import * as types from '@/types/message-types'
import StatusAvatar from '@/components/StatusAvatar'
import ReadReceivedMessageIcon from '../ReadReceivedMessageIcon.vue'
import { markAsRead } from '@/signalr/chat.js'
export default {
  components: {
    TextMessage,
    AudioMessage,
    ChatBotMessage,
    GeolocationMessage,
    OfferMessage,
    DealMessage,
    FileMessage,
    ReadReceivedMessageIcon,
    StatusAvatar
  },
  props: {
    value: Object,
    threadId: String,
    isArchive: Boolean,
    idToUseAsCurrentUser: String,
    minimalize: Boolean
  },
  data () {
    return {
      types
    }
  },
  watch: {
    minimalize (value) {
      if (!value) this.markAsRead()
    }
  },
  computed: {
    type () {
      return this.value?.type
    },
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    isCurrentUserSender () {
      return this.currentUserId === this.value.sender
    },
    currentUserMessage () {
      return this.value?.users?.find((el) => el.id === this.currentUserId) ?? {}
    },
    othreUserMessage () {
      return this.value?.users?.find((el) => el.id !== this.currentUserId) ?? {}
    }
  },
  methods: {
    markAsRead () {
      if (!this.currentUserMessage?.read && !this.minimalize) {
        markAsRead(this.threadId, this.value.id)
      }
    }
  }
}
</script>

<style>
.message {
  min-width: 10%;
  max-width: 90%;
  word-wrap: break-word;
  word-break: break-all;
  line-break: auto;
  display: inline-grid;
}
.text-wrapper {
  background-color: var(--v-primary-base);
  color: white;
}
.current-user-sender {
  margin-left: auto;
}
.current-user-sender .text-wrapper {
  background: #e0e0e0;
  color: black;
}
.chat-menu:hover {
  background-color: var(--v-accent-base) !important;
  color:  var(--v-primary-base) !important;
}
.menu {
  z-index: 1000;
}
</style>
