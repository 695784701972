import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import './styles/style.scss'
import '@/services/index'
import { getPermission } from '@/plugins/permission'
import DataTable from '@/components/DataTable'
import '@/filters/createFilters'
import VueGtag from 'vue-gtag'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { useFavicon } from '@vueuse/core'
import VueTour from 'vue-tour'

console.log(`Spedimo v.${process.env.VUE_APP_VERSION}`)

Vue.use(VueTelInputVuetify, {
  vuetify
})

Vue.component('data-table', DataTable)

Vue.config.productionTip = false

Vue.prototype.$permission = getPermission

switch (window.location.hostname) {
  case 'staging.24tsl.eu':
  case 'app.spedimo.eu':
    Vue.use(VueGtag, {
      config: { id: 'G-HV2BJMLT6B' }
    })
    break
  case 'test.24tsl.eu':
    Vue.use(VueGtag, {
      config: { id: 'G-SZHYKY673J' }
    })
    break
}

Vue.use(VueReCaptcha, { siteKey: '6LeAeOwkAAAAANkJwzbOav32d_TatN3ORDvMMKzY' })
require('vue-tour/dist/vue-tour.css')

const icon = useFavicon()
icon.value = 'static/img/favicon.png'
Vue.use(VueTour)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
