<template>
  <div class="cooperation-status" :style="{background: cooperationStatusColor}">
  </div>
</template>

<script>
import * as colors from '@/types/status-colors.js'
import * as cooperationStatus from '@/types/cooperation-status.js'
export default {
  props: {
    users: Array
  },
  data () {
    return {
      cooperationStatuses: {
        [cooperationStatus.RELIABLE]: colors.RELIABLE,
        [cooperationStatus.WITHRESERVATIONS]: colors.WITHRESERVATIONS,
        [cooperationStatus.UNRELIABLE]: colors.UNRELIABLE,
        [cooperationStatus.NONE]: colors.NONE
      }
    }
  },
  computed: {
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    usersWithoutCurrent () {
      return this.users.filter((el) => el.user.id !== this.currentUserId)
    },
    otherUser () {
      return this.usersWithoutCurrent[0]?.user
    },
    otherUserId () {
      return this.otherUser?.id
    },
    userIsWorker () {
      return this.$store.getters.isOwnerOrWorker
    },
    contacts () {
      return this.$store.getters.contacts?.contacts
    },
    groups () {
      return this.$store.getters.contacts?.groups
    },
    currentContact () {
      let contact = this.contacts?.find((el) => el.user.id === this.otherUserId)
      if (!contact) {
        if (this.groups && this.groups.length > 0) {
          this.groups.forEach(element => {
            const user = element.users.find((el) => el.user.id === this.otherUserId)
            if (user) {
              contact = user
            }
          })
        }
      }
      return contact
    },
    showStatus () {
      return this.currentContact?.showCooperationStatus
    },
    cooperationStatusColor () {
      if (!this.currentContact) {
        return this.cooperationStatuses[cooperationStatus.NONE]
      } else {
        return this.cooperationStatuses[this.currentContact.cooperationStatus]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cooperation-status {
  height: 4px;
  border-radius: 5px 5px 0 0;
}
</style>
