import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import * as getters from './getters'
import mutations from './mutations'
import * as actions from './actions'

import notification from './modules/notifications'
import users from './modules/users'
import chat from './modules/chat'
import market from './modules/market'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    notification,
    users,
    chat,
    market
  }
})
