import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts/Layout'
import {
  afterEach,
  beforeEach,
  guardGuest,
  guardLogin,
  guardPermission,
  guardRole
} from '@/router/guards'
import {
  adminRoleId,
  ownerRoleId,
  workerRoleId,
  customerServiceRoleId, managerRoleId
} from '@/utils/helpers'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    beforeEnter: guardLogin,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home')
      },
      {
        path: 'customerService/employees',
        name: 'EmployeesCustomerService',
        component: () =>
          import(
            /* webpackChunkName: "Employees" */ '../views/Employees/Employees.vue'
          ),
        meta: {
          roles: [adminRoleId]
        },
        beforeEnter: guardRole
      },
      {
        path: 'customerService/issues/activeTab/:activeTab',
        name: 'IssuesCustomerService',
        component: () =>
          import(/* webpackChunkName: "Issues" */ '@/views/Issues/Issues.vue'),
        meta: {
          permission: 'IssuesModuleVisibility'
        },
        beforeEnter: guardPermission
      },
      {
        path: 'customerService/issues/add',
        name: 'AddIssueCustomerService',
        component: () =>
          import(/* webpackChunkName: "AddIssue" */ '@/views/Issues/Save.vue')
        // meta: {
        //   permission: 'IssueTakeover'
        // },
        // beforeEnter: guardPermission
      },
      {
        path: 'customerService/issues/drafts/:id',
        name: 'DetailsDraftIssueCustomerService',
        component: () =>
          import(
            /* webpackChunkName: "DetailsDraftIssue" */ '@/views/Issues/Save.vue'
          )
        // meta: {
        //   permission: 'IssueTakeover'
        // },
        // beforeEnter: guardPermission
      },
      {
        path: 'customerService/issues/:id',
        name: 'DetailsIssueCustomerService',
        component: () =>
          import(
            /* webpackChunkName: "DetailsIssue" */ '@/views/Issues/Details.vue'
          )
        // meta: {
        //   permission: 'IssueTakeover'
        // },
        // beforeEnter: guardPermission
      },
      {
        path: 'customerService/impersonation',
        name: 'ImpersonationCustomerService',
        component: () =>
          import(
            /* webpackChunkName: "Impersonation" */ '../views/Impersonation/Impersonation.vue'
          ),
        meta: {
          roles: [adminRoleId, customerServiceRoleId]
        },
        beforeEnter: guardRole
      },
      {
        path: 'customerService/companies',
        name: 'CompaniesCustomerService',
        component: () =>
          import(
            /* webpackChunkName: "Companies" */ '@/views/Companies/CustomerService/Companies'
          ),
        meta: {
          permission: 'CompanyModuleVisibility'
        },
        beforeEnter: guardPermission
      },
      {
        path: 'companies/',
        name: 'CompaniesMarket',
        component: () =>
          import(
            /* webpackChunkName: "Companies" */ '@/views/Companies/Market/Companies.vue'
          ),
        meta: {
          roles: [workerRoleId, ownerRoleId, managerRoleId]
        },
        beforeEnter: guardRole
      },
      {
        path: 'cargoPlanner/',
        name: 'CargoPlanner',
        component: () =>
          import(
            /* webpackChunkName: "Companies" */ '@/views/Planner/CargoPlanner.vue'
          ),
        meta: {
          roles: [workerRoleId, ownerRoleId, managerRoleId]
        },
        beforeEnter: guardRole
      },
      {
        path: 'customerService/companiesIssues/activeTab/:activeTab',
        name: 'CompaniesIssuesCustomerService',
        component: () =>
          import(
            /* webpackChunkName: "CompaniesIssues" */ '@/views/Companies/CustomerService/CompaniesIssues'
          ),
        meta: {
          permission: 'IssuesModuleVisibility'
        },
        beforeEnter: guardPermission
      },
      {
        path: 'customerService/companies/:id',
        name: 'CompaniesDetailsCustomerService',
        component: () =>
          import(
            /* webpackChunkName: "CompaniesDetails" */ '../views/Companies/CustomerService/CompaniesDetails/CompaniesDetails.vue'
          ),
        meta: {
          permission: 'CompanyModuleVisibility'
        },
        beforeEnter: guardPermission
      },
      {
        path: 'customerService/settings/system',
        name: 'SystemCustomerService',
        component: () =>
          import(
            /* webpackChunkName: "System" */ '../views/Settings/System/System.vue'
          )
      },
      {
        path: 'settings/profile',
        name: 'Profile',
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ '../views/Settings/Profile/Profile.vue'
          )
      },
      {
        path: 'reviews',
        name: 'Reviews',
        component: () =>
          import(
            /* webpackChunkName: "Reviews" */ '../views/Reviews/Reviews.vue'
          )
      },
      {
        path: 'customerService/user-profile/:userId',
        name: 'CustomerServiceUserProfile',
        component: () => import('../views/UserProfile/UserProfile.vue')
      },
      {
        path: 'user-profile/:userId',
        name: 'UserProfile',
        component: () => import('../views/UserProfile/UserProfile.vue')
      },
      {
        path: 'chat/settings',
        name: 'Chat',
        component: () => import('../views/Settings/Chat/Chat.vue')
      },
      {
        path: 'archive/:search?',
        name: 'Archive',
        component: () => import('../views/Settings/Chat/Archive.vue')
      },
      {
        path: 'chat/archive-bok/',
        name: 'Archive',
        component: () => import('../views/Settings/Chat/ArchiveBok.vue'),
        meta: {
          roles: [adminRoleId, customerServiceRoleId]
        },
        beforeEnter: guardRole
      },
      {
        path: 'chat/blocked-users',
        name: 'BlockUsersList',
        component: () => import('../views/Settings/Chat/BlockedUsersList.vue')
      },
      // {
      //   path: 'issue-register/:id',
      //   name: 'IssueRegister',
      //   component: () => import(/* webpackChunkName: "IssueRegister" */ '../views/IssueRegister/IssueRegister.vue')
      // },
      {
        path: 'register-issue/:id',
        name: 'RegisterIssueCustomerService',
        component: () =>
          import(
            /* webpackChunkName: "RegisterIssue" */ '../views/IssueRegister/RegisterIssue.vue'
          )
      },
      {
        path: 'company-details/:id',
        name: 'CompanyDetailsMarket',
        component: () =>
          import('../views/Companies/Market/CompanyDetails/CompanyDetails')
      },
      {
        path: 'my-company',
        name: 'MyCompany',
        component: () =>
          import('../views/Companies/Market/CompanyDetails/CompanyDetails')
      },
      {
        path: 'helpdesk',
        name: 'HelpDesk',
        component: () => import('../views/HelpDesk/Chats')
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: () => import('../views/Statistics/Statistics')
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import('../views/Reports/Reports')
      },
      {
        path: 'market/vehicle/add',
        name: 'AddVehicle',
        component: () => import('../views/Market/AddVehicle.vue')
      },
      {
        path: 'market/vehicle/copy/:id',
        name: 'CopyVehicle',
        component: () => import('../views/Market/AddVehicle.vue')
      },
      {
        path: 'market/my-vehicles',
        name: 'MyVehicles',
        component: () => import('../views/Market/MyVehicles.vue')
      },
      {
        path: 'market/vehicles',
        name: 'Vehicles',
        component: () => import('../views/Market/Vehicles.vue')
      },
      {
        path: 'market/loads',
        name: 'Loads',
        component: () => import('../views/Market/Loads')
      },
      {
        path: 'market/freight/add',
        name: 'AddFreight',
        component: () => import('../views/Market/Freight/AddFreight.vue')
      },
      {
        path: 'market/freight/copy/:id',
        name: 'CopyFreight',
        component: () => import('../views/Market/Freight/AddFreight.vue')
      },
      {
        path: 'market/frachts',
        name: 'Frachts',
        component: () => import('../views/Market/Frachts.vue')
      },
      {
        path: 'customerService/dashboard',
        name: 'CustomerServiceDashboard',
        component: () => import('../views/Dashboard/CustomerServiceDashboard.vue'),
        meta: {
          roles: [adminRoleId, customerServiceRoleId]
        },
        beforeEnter: guardRole
      }
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "ChatLayout" */ '../layouts/ChatLayout'),
    beforeEnter: guardLogin,
    children: [{
      path: 'unpined-chat/:id/:threadId?',
      name: 'UnpinedChat',
      component: () => import('../views/UnpinedChat.vue')
    }]
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "Register" */ '../views/auth/Register'),
    beforeEnter: guardGuest
  },
  {
    path: '/',
    name: 'GlobalLayout',
    component: () =>
      import(/* webpackChunkName: "GlobalLayout" */ '../layouts/GlobalLayout'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () =>
          import(/* webpackChunkName: "Login" */ '../views/auth/Login'),
        beforeEnter: guardGuest
      },
      {
        path: 'bok/login',
        name: 'LoginBOK',
        component: () =>
          import(/* webpackChunkName: "Login" */ '../views/auth/Login'),
        beforeEnter: guardGuest
      },
      {
        path: 'forgotten-password',
        name: 'ForgottenPassword',
        component: () =>
          import(
            /* webpackChunkName: "ForgottenPassword" */ '../views/auth/ForgottenPassword'
          ),
        beforeEnter: guardGuest
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () =>
          import(
            /* webpackChunkName: "ResetPassword" */ '../views/auth/ResetPassword'
          ),
        beforeEnter: guardGuest
      },
      {
        path: 'reset-password/mobile',
        name: 'ResetPasswordMobile',
        component: () =>
          import(
            /* webpackChunkName: "ResetPasswordMobile" */ '../views/auth/ResetPassword'
          )
      },
      {
        path: 'two-factor-authentication',
        name: 'TwoFactorAuthentication',
        component: () =>
          import(
            /* webpackChunkName: "TwoFactorAuthentication" */ '../views/auth/TwoFactorAuthentication'
          ),
        beforeEnter: guardGuest
      },
      {
        path: 'reset-password-code-verification',
        name: 'ResetPasswordCodeVerification',
        component: () =>
          import(
            /* webpackChunkName: "ResetPasswordCodeVerification" */ '../views/auth/ResetPasswordCodeVerification'
          ),
        beforeEnter: guardGuest
      },
      {
        path: 'add-device',
        name: 'AddDevice',
        component: () =>
          import(/* webpackChunkName: "AddDevice" */ '../views/auth/AddDevice'),
        beforeEnter: guardGuest
      },
      //  ERROR PAGES
      {
        path: '404',
        name: 'Error404',
        component: () =>
          import(/* webpackChunkName: "Error404" */ '../views/Error404')
      },
      {
        path: '429',
        name: 'Error429',
        component: () =>
          import(/* webpackChunkName: "Error429" */ '../views/Error429')
      },
      {
        path: '500',
        name: 'Error500',
        props: true,
        component: () =>
          import(/* webpackChunkName: "Error500" */ '../views/Error500')
      },
      {
        path: '*',
        component: () =>
          import(/* webpackChunkName: "Error404" */ '../views/Error404')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  return beforeEach(to, from, next)
})

router.afterEach((to) => {
  return afterEach(to)
})

export default router
